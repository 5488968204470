<template>
  <div style="height: 700px; overflow: auto">
    <!-- 20为安卓   10为ios -->
    <el-table size="small" stripe fit ref="multipleTable" tooltip-effect="dark" class="sort_table" style="width: 99%; margin: 8px" :header-cell-style="{ background: '#f7f8fa', color: '#606266' }" :data="form">
      <!-- 序号 -->
      <el-table-column :label="$t('DeviceManage.Device.Application.SerialNumber')" width="80" :show-overflow-tooltip="true" type="index" prop="userInfo.userName">
      </el-table-column>

      <!-- 应用名称 -->
      <el-table-column :label="$t('public.ApplicationName')" :show-overflow-tooltip="true" width="240" prop="appName" v-if="user.osId == 20">
        <template v-slot="{ row }">
          <template> {{row.appName}} </template>
        </template>
      </el-table-column>

      <!-- 应用名称 -->
      <el-table-column :label="$t('public.ApplicationName')" :show-overflow-tooltip="true" width="290" prop="name" v-if="user.osId == 10">
      </el-table-column>

      <!-- 应用ID -->
      <el-table-column :label="$t('public.ApplicationID')" :show-overflow-tooltip="true" width="290" prop="packageName" v-if="user.osId == 20">
      </el-table-column>

      <!-- 应用ID -->
      <el-table-column :label="$t('public.ApplicationID')" :show-overflow-tooltip="true" width="290" prop="identifier" v-if="user.osId == 10">
      </el-table-column>

      <!-- 版本 -->
      <el-table-column :label="$t('public.Version')" :show-overflow-tooltip="true" width="290" prop="versionName" v-if="user.osId == 20">
      </el-table-column>

      <!-- 版本 -->
      <el-table-column :label="$t('public.Version')" :show-overflow-tooltip="true" width="290" prop="shortversion" v-if="user.osId == 10">
      </el-table-column>

      <!-- 大小 -->
      <el-table-column :label="$t('public.Size')" :show-overflow-tooltip="true" width="290" prop="appSize" v-if="user.osId == 20">
      </el-table-column>

      <!-- 大小 -->
      <el-table-column :label="$t('public.Size')" :show-overflow-tooltip="true" width="290" prop="bundleSize" v-if="user.osId == 10">
        <template v-slot="{ row }">
          <template v-if="row.bundleSize == '0B'"> N/A </template>
          <template v-else> {{row.bundleSize}} </template>
        </template>
      </el-table-column>

      <!-- 应用类型 -->
      <el-table-column :label="$t('DeviceManage.Device.Application.Applicationtype')" :show-overflow-tooltip="true" prop="preventScreenshot" v-if="user.osId == 20">
        <template v-slot="{ row }">
          <!-- <template v-if="row.preventScreenshot == 0"> {{$t('DeviceManage.Device.Application.Protectiveapp')}} </template>
          <template v-else> {{$t('DeviceManage.Device.Application.Ordinaryapp')}} </template> -->
          <!-- v6.1.1 -->
          <template v-if="row.isSystem == 0"> 系统应用 </template>
          <template v-if="row.isSystem == 1"> {{$t('DeviceManage.Device.Application.Ordinaryapp')}} </template>
          <template v-if="row.isSystem == 2"> 加固应用 </template>
        </template>
      </el-table-column>

      <!-- 安装模式 -->
      <el-table-column :label="$t('DeviceManage.Device.Application.Installationmode')" :show-overflow-tooltip="true" prop="preventScreenshot" v-if="user.osId == 20">
        <template v-slot="{ row }">
          <template v-if="row.installType == ''"> / </template>
          <template v-else-if="row.installType == 0 || row.installType == null"> 普通安装 </template>
          <template v-else-if="row.installType == 1"> 安全域安装 </template>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: [],
      user: {
        osId: ''
      }
    }
  },

  created() {},

  mounted() {
    let user = JSON.parse(window.sessionStorage.getItem('user'))
    this.user = user
    setTimeout(() => {
      console.log(this.user.osId)
    }, 3000)
    this.getlist(user)
  },

  methods: {
    async getlist(user) {
      var param = {
        deviceId: user.id,
        deviceUDID: user.deviceUDID,
        osType: user.osId == 20 ? 'Android' : 'iOS'
        // status: user.status,
        // rowNumber: 10,
      }
      // console.log(data);
      const res = await this.$axios.post(
        // "api/webSoftware/deviceSoftwareInfo.do",
        'httpServe/webSoftware/deviceSoftwareInfo',
        param,
        true
      )
      console.log(res.data[0].installType)
      this.form = res.data
    }
  }
}
</script>

<style lang="scss" scoped></style>
