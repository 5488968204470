export default function MapLoader () {   
    return new Promise((resolve, reject) => {
        var script = document.createElement('script')
        script.type = 'text/javascript'
        script.async = true
        script.src = 'https://webapi.amap.com/maps?v=1.4.15&&key=00950ebeb1b08e4fbf8188bb92b27293&plugin=AMap.CircleEditor,AMap.RectangleEditor,AMap.Autocomplete,AMap.PlaceSearch,AMap.Walking,AMap.Driving'
        script.onerror = reject
        document.head.appendChild(script)
        window.initAMap = () => {
            resolve(window.AMap)
        }
    })
  }