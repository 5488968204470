<template>
  <div style="height: 700px; overflow: auto">
    <!-- 20为安卓   10为ios -->
    <el-row :gutter="3" style="margin:0 7px;">
      <el-col :span="3">
        <el-select v-model="formInline.appName" style="width: 100%" :placeholder="$t('public.NoData')" size="small" class="placeInput">
            <el-option v-for="(item,index) in typeOptions" :key="index" :label="item.label" :value="item.value">
            </el-option>
        </el-select>
      </el-col>
      <!-- 查询 -->
      <el-col :span="3"><el-button type="primary" size="small" class="save" @click="searchList">{{$t('public.Inquire')}}</el-button></el-col>
    </el-row>
    <el-table size="small" stripe fit ref="multipleTable" tooltip-effect="dark" class="sort_table" style="width: 99%; margin: 8px" :header-cell-style="{ background: '#f7f8fa', color: '#606266' }" :data="form">
      <el-table-column :label="$t('DeviceManage.Device.Application.Applicationtype')" :show-overflow-tooltip="true" width="155" type="index" prop="userInfo.userName">
      </el-table-column>
      <el-table-column :label="$t('DeviceManage.Device.AppThreats.Threattype')" :show-overflow-tooltip="true" width="290" prop="type">
        <template v-slot="{ row }">
            <template v-if="row.type == 'S_CHECK_LOCATION_FRAUD'"><span>{{$t('DeviceManage.Device.AppThreats.Locationfraud')}}</span>
            </template>
            <template v-else-if="row.type == 'S_CHECK_DOMAIN'">
              <span>{{$t('DeviceManage.Device.AppThreats.Domainnamefraud')}}</span>
            </template>
            <template v-else-if="row.type == 'S_CHECK_INJECT_STATUS'">
              <span>{{$t('DeviceManage.Device.AppThreats.Injectionattack')}}</span>
            </template>
            <template v-else-if="row.type == 'S_CHECK_DEBUG_STATUS'">
              <span>{{$t('DeviceManage.Device.AppThreats.Debuggingbehavior')}}</span>
            </template>
            <template v-else-if="row.type == 'S_CHECK_MEMORY_CHANGE'">
              <span>{{$t('DeviceManage.Device.AppThreats.Memorytampering')}}</span>
            </template>
            <template v-else-if="row.type == 'S_CHECK_PLUGIN'">
              <span>{{$t('DeviceManage.Device.AppThreats.Programplugin')}}</span>
            </template>
            <template v-else-if="row.type == 'S_CHECK_SIGNATURE'">
              <span>{{$t('DeviceManage.Device.AppThreats.Applicationcrack')}}</span>
            </template>
            <template v-else-if="row.type == 'S_CHECK_ABNORMAL_CA'">
              <span>{{$t('DeviceManage.Device.AppThreats.HTTPShijacking')}}</span>
            </template>
            <template v-else-if="row.type == 'S_CHECK_WIFI_PROXY'">
              <span>{{$t('DeviceManage.Device.AppThreats.Httpproxy')}}</span>
            </template>
            <template v-else-if="row.type == 'S_CHECK_MULTI_APK'">
              <span>{{$t('DeviceManage.Device.AppThreats.AppMultipleOpen')}}</span>
            </template>
            <template v-else-if="row.type == 'S_CHECK_FRAME_ATTACK'">
              <span>{{$t('DeviceManage.Device.AppThreats.Frameworkattacks')}}</span>
            </template>
            <template v-else-if="row.type == 'S_CHECK_FREQUENCY_ACCOUNT'">
              <span>{{$t('DeviceManage.Device.AppThreats.Trebleaccount')}}</span>
            </template>
            <template v-else-if="row.type == 'S_CHECK_FREQUENCY_IP'">
              <span>{{$t('DeviceManage.Device.AppThreats.TrebleIP')}}</span>
            </template>
            <template v-else-if="row.type == 'S_CHECK_FREQUENCY_LOCATION'">
              <span>{{$t('DeviceManage.Device.AppThreats.Trebleposition')}}</span>
            </template>
            <template v-else-if="row.type == 'S_CHECK_FREQUENCY_RESTART'">
              <span>{{$t('DeviceManage.Device.AppThreats.Treblestartup')}}</span>
            </template>
            <template v-else-if="row.type == 'S_CHECK_DANGER_APPS'">
              <span>{{$t('DeviceManage.Device.AppThreats.Riskapplication')}}</span>
            </template>
            <template v-else-if="row.type == 'S_CHECK_ROOT_STATUS'">
              <span>{{$t('DeviceManage.Device.AppThreats.ROOTJailbreak')}}</span>
            </template>
            <template v-else-if="row.type == 'S_FRAME_ATTACH'">
              <span>{{$t('DeviceManage.Device.AppThreats.Framesoftware')}}</span>
            </template>
            <template v-else-if="row.type == 'S_ADB_ENABLED'">
              <span>{{$t('DeviceManage.Device.BasicNews.USBdebugging')}}</span>
            </template>
            <template v-else-if="row.type == 'S_CHECK_VM_STATUS'">
              <span>{{$t('DeviceManage.Device.BasicNews.Simulator')}}</span>
            </template>
            <template v-else-if="row.type == 'S_ALLOW_MOCK_LOCATION'">
              <span>{{$t('DeviceManage.Device.BasicNews.Simulatedposition')}}</span>
            </template>
            <template v-else-if="row.type == 'S_CHECK_SYS_USER_CA'">
              <span>{{$t('DeviceManage.Device.AppThreats.Rootcertificate')}}</span>
            </template>
            <template v-else-if="row.type == 'S_CHECK_CUSTOM_ROM'">
              <span>{{$t('DeviceManage.Device.AppThreats.CustomROM')}}</span>
            </template> 
            <template v-else-if="row.type == 'S_CHECK_SYSTEM_TIME'">
              <span>{{$t('DeviceManage.Device.AppThreats.Systemtimeanomaly')}}</span>
            </template>
            <template v-else-if="row.type == 'S_CHECK_SCREEN_PASSWORD'">
              <span>{{$t('DeviceManage.Device.AppThreats.Locknotenabled')}}</span>
            </template>
            <template v-else-if="row.type == 'S_MACOS_STATUS'">
              <span>macOS</span>
            </template>
            <template v-else-if="row.type == 'S_WINDOWS_STATUS'">
              <span>Windows</span>
            </template>
            <template v-else-if="row.type == 'S_VPN_STATUS'">
              <span>VPN</span>
            </template>
            <template v-else-if="row.type == 'S_CLOUD_PHONE_STATUS'">
              <span>{{$t('DeviceManage.Device.BasicNews.CloudPhone')}}</span>
            </template>
            <template v-else-if="row.type == 'S_CHECK_DANGER_IP'">
              <span>{{$t('DeviceManage.Device.AppThreats.RiskIP')}}</span>
            </template>
            <template v-else-if="row.type == 'S_CHECK_SCREENSHOT_STATUS'">
              <span>{{$t('DeviceManage.Device.AppThreats.ScreenshotRelease')}}</span>
            </template>
            <template v-else-if="row.type == 'S_FALSE_DEVICE'">
              <span>{{$t('DeviceManage.Device.BasicNews.Equipmentforgery')}}</span>
            </template>
            <template v-else-if="row.type">
              <span>{{ row.type }}</span>
            </template>
          </template>
      </el-table-column>
      <el-table-column :label="$t('public.Behaviorlevel')" :show-overflow-tooltip="true" width="290" prop="level">
        <template v-slot="{row}">
          <span class="detaildesc" v-if="row.level == '3'" style="color: #d9001b; cursor: auto">{{$t('DeviceManage.Device.AppThreats.Tall')}}</span>
          <span class="detaildesc" v-if="row.level == '2'" style="color: #f59a23; cursor: auto">{{$t('DeviceManage.Device.AppThreats.Centre')}}</span>
          <span class="detaildesc" v-if="row.level == '1'" style="color: #00cdcd; cursor: auto">{{$t('DeviceManage.Device.AppThreats.Low')}}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('DeviceManage.Device.AppThreats.Threatstatus')" :show-overflow-tooltip="true" width="290" prop="status">
        <template v-slot="{row}">
          <span v-if="row.status == 0">{{$t('public.Normal')}}</span>
          <span v-if="row.status == 1" style="color: #d9001b;">{{$t('DeviceManage.Device.BasicNews.Abnormal')}}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('public.GenerationTime')" :show-overflow-tooltip="true" prop="updateTime">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: [],
      typeOptions:[],
      formInline:{
        appName:""
      },
      user: {
        osId: ''
      }
    }
  },

  created() {},
  mounted() {
    let user = JSON.parse(window.sessionStorage.getItem('user'))
    this.user = user
    this.getselectList(user)
  },

  methods: {
    async getselectList(user){
      let params = {
        udid:this.user.deviceUDID
      }
      const res = await this.$axios.post(
        'httpServe/device/threat/appName',
        params,
        true
      )
      let dataArray = []
      res.data.forEach((item)=>{
        dataArray.push({
          label:item,
          value:item
        })
      })
      this.typeOptions = dataArray
      // let data = {
      //   label:'安全桌面',
      //   value:'安全桌面'
      // }
      // this.typeOptions.push(data)
      this.typeOptions.forEach((item)=>{
        if(item.label == "安全桌面"){
          this.formInline.appName = "安全桌面"
        }else {
          this.formInline.appName = '安全桌面'
        }
      })
      if(this.typeOptions.length>0){
        this.getlist()
      }
    },
    searchList(){
      if(this.typeOptions.length>0){
        this.getlist()
      }
    },
    async getlist(user) {
      var param = {
        udid: this.user.deviceUDID,
        appName: this.formInline.appName,
      }
      const res = await this.$axios.post(
        'httpServe/device/threat/appDetail',
        param,
        true
      )
      this.form = res.data
    }
  }
}
</script>
<style lang="scss" scoped></style>
