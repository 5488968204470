<template>
  <div style="display: flex; height: 700px">
    <div style="margin-top: 0px">
      <el-form ref="form" :model="form" label-width="190px">

        <!-- 设备名称 -->
        <el-form-item :label="$t('public.DeviceName') + '：'"> 
          <el-form-item v-if="form.deviceName">
            {{ form.deviceName }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A' }}
          </el-form-item>
        </el-form-item>

        <!-- 健康度： -->
        <el-form-item :label="$t('DeviceManage.Device.Healthdegree') + '：'">
          <el-form-item v-if="form.healthDegree">
            {{ form.healthDegree }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A' }}
          </el-form-item>
        </el-form-item>

        <!-- 合规状态 -->
        <el-form-item :label="$t('DeviceManage.Device.Compliancestatus') + '：'">
          <el-form-item v-if="form.isViolation == 0"> {{ $t('DeviceManage.Device.Getoutofline') }} </el-form-item>
          <el-form-item v-else-if="form.isViolation == 1"> {{ $t('public.Normal') }} </el-form-item>
          <el-form-item v-else> {{ $t('DeviceManage.Device.Unknown') }} </el-form-item>
        </el-form-item>

        <!-- 设备状态 -->
        <el-form-item :label="$t('DeviceManage.Device.EquipmentStatus') + '：'">
          <el-form-item v-if="form.status == 5"> {{ $t('DeviceManage.Device.BasicNews.Notactive') }} </el-form-item>
          <el-form-item v-else-if="form.status == 10"> {{ $t('DeviceManage.Device.Pendingregistration') }} </el-form-item>
          <el-form-item v-else-if="form.status == 15"> {{ $t('DeviceManage.Device.BasicNews.Active') }} </el-form-item>
          <el-form-item v-else-if="form.status == 25"> {{ $t('DeviceManage.Device.SignOUT') }} </el-form-item>
          <el-form-item v-else-if="form.status == 35"> {{ $t('public.Outofservice') }} </el-form-item>
          <el-form-item v-else> {{ 'N/A' }} </el-form-item>
        </el-form-item>

        <!-- 在线状态 -->
        <el-form-item :label="$t('DeviceManage.Device.Onlinesta') + '：'">
          <el-form-item v-if="form.deviceConIMStatus.conStatus == 0"> 
            {{ $t('DeviceManage.Device.Offline') }}
          </el-form-item>
          <el-form-item v-else-if="form.deviceConIMStatus.conStatus == 1">
            {{ $t('DeviceManage.Device.Online') }}
          </el-form-item>
          <el-form-item v-else> {{ $t('DeviceManage.Device.Unknown') }} </el-form-item>
        </el-form-item>

        <!-- 隧道状态 -->
        <el-form-item :label="$t('DeviceManage.Device.Tunnelstatus') + '：'">
          <!-- Forbidden 0禁用 1启用-->
          <el-form-item v-if="form.tunnelStatus == 0"> {{ $t('public.Forbidden') }} </el-form-item>
          <el-form-item v-else-if="form.tunnelStatus == 1">
            {{ $t('public.Enable') }}
          </el-form-item>
          <!-- 未知 -->
          <el-form-item v-else> {{ $t('DeviceManage.Device.Unknown') }} </el-form-item>
        </el-form-item>

        <!-- 蓝牙 -->
        <el-form-item :label="$t('DeviceManage.Device.BasicNews.Bluetooth') + '：'">
          <el-form-item v-if="hardwareInfo_bank.bluetooth == '0'">
            {{ $t('DeviceManage.Device.BasicNews.Close') }}
          </el-form-item>
          <el-form-item v-else-if="hardwareInfo_bank.bluetooth == '1'">
            {{ $t('DeviceManage.Device.BasicNews.Open') }}
          </el-form-item>
          <el-form-item v-else> {{ $t('DeviceManage.Device.Unknown') }} </el-form-item>
        </el-form-item>

        <!-- WLAN网络 -->
        <el-form-item :label="$t('DeviceManage.Device.BasicNews.WLANnetwork') + '：'">
          <el-form-item v-if="hardwareInfo_bank.wlanNet == '0'"> {{ $t('DeviceManage.Device.BasicNews.Close') }}
          </el-form-item>
          <el-form-item v-else-if="hardwareInfo_bank.wlanNet == '1'">
            {{ $t('DeviceManage.Device.BasicNews.Open') }}
          </el-form-item>
          <el-form-item v-else> {{ $t('DeviceManage.Device.Unknown') }} </el-form-item>
        </el-form-item>

        <!-- 存储空间 -->
        <el-form-item :label="$t('DeviceManage.Device.BasicNews.Storagespace') + '：'">
          <el-form-item v-if="deviceInfo_bank.totaldatasize">
            {{ deviceInfo_bank.totaldatasize }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A' }}
          </el-form-item>
        </el-form-item>

        <!-- 可用存储空间 -->
        <el-form-item :label="$t('DeviceManage.Device.BasicNews.Availablestoragespace') + '：'">
          <el-form-item v-if="deviceInfo_bank.availdatasize">
            {{ deviceInfo_bank.availdatasize }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A' }}
          </el-form-item>
        </el-form-item>

        <!-- 全部内存 -->
        <el-form-item :label="$t('DeviceManage.Device.BasicNews.Totalmemory') + '：'">
          <el-form-item v-if="hardwareInfo_bank.totalmemorysize">
            {{ hardwareInfo_bank.totalmemorysize }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A' }}
          </el-form-item>
        </el-form-item>
      </el-form>
    </div>
    <div style="margin-top: 0px">
      <el-form ref="form" :model="form" label-width="300px">
        <!-- 客户端版本 -->
        <el-form-item :label="$t('DeviceManage.Device.BasicNews.Clientversion') + '：'">
          <el-form-item v-if="deviceInfo_bank.clientversion">
            {{ deviceInfo_bank.clientversion }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A' }}
          </el-form-item>
        </el-form-item>

        <!-- 设备厂商 -->
        <el-form-item :label="$t('DeviceManage.Device.BasicNews.Manufacturer') + '：'">
          <el-form-item v-if="deviceInfo_bank.brand">
            {{ deviceInfo_bank.brand }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A' }}
          </el-form-item>
        </el-form-item>

        <!-- 设备型号 -->
        <el-form-item :label="$t('DeviceManage.Device.BasicNews.EquipmentModel') + '：'">
          <el-form-item v-if="deviceInfo_bank.model">
            {{ deviceInfo_bank.model }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A' }}
          </el-form-item>
        </el-form-item>

        <!-- 序列号 -->
        <el-form-item :label="$t('DeviceManage.Device.BasicNews.Serialnumber') + '：'">
          <el-form-item v-if="hardwareInfo_bank.serial">
            {{ hardwareInfo_bank.serial }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A' }}
          </el-form-item>
        </el-form-item>

        <!-- 操作系统 -->
        <!-- <el-form-item :label="$t('DeviceManage.Device.BasicNews.Operatingsystem') + '：'">
          <el-form-item v-if="form.deviceOs.osType">
            {{ form.deviceOs.osType }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A' }}
          </el-form-item>
        </el-form-item> -->
        <el-form-item :label="$t('DeviceManage.Device.BasicNews.Operatingsystem') + '：'">
          <el-form-item v-if="hardwareInfo_bank.OS">
            {{ hardwareInfo_bank.OS }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A' }}
          </el-form-item>
        </el-form-item>

        <!-- v6.3.0新增 系统名称 -->
        <el-form-item :label="$t('DeviceManage.Device.BasicNews.Systemname') + '：'"  v-if="user.osId == 40">
          <el-form-item v-if="hardwareInfo_bank.osName">
            {{ hardwareInfo_bank.osName }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A' }}
          </el-form-item>
        </el-form-item>

        <!-- 系统版本 -->
        <el-form-item :label="$t('public.SystemVersion') + '：'">
          <el-form-item v-if="deviceInfo_bank.releaseVersion">
            {{ deviceInfo_bank.releaseVersion }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A' }}
          </el-form-item>
        </el-form-item>

        <!-- 电池电量 -->
        <el-form-item :label="$t('DeviceManage.Device.BasicNews.Batterylevel') + '：'">
          <el-form-item v-if="deviceInfo_bank.battery">
            {{ deviceInfo_bank.battery }}%
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A' }}
          </el-form-item>
        </el-form-item>

        <!-- UDID -->
        <el-form-item label="UDID：">
          <el-form-item v-if="form.deviceUDID">
            {{ form.deviceUDID }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A' }}
          </el-form-item>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //内存使用率
      memoryUsage: '',
      form: {
        deviceName: '',
        androidHardwareInfo: {
          availdatasize: '',
          availmemorysize: '',
          battery: '',
          board: '',
          bootLoader: '',
          brand: '',
          clientversion: '',
          codeName: '',
          cpuAbi: '',
          cpuAbi2: '',
          dataState: '',
          device: '',
          deviceId: '',
          deviceSoftwareVersion: '',
          display: '',
          fingerPrint: '',
          groupIdLevel1: '',
          hardware: '',
          hasIccCard: '',
          host: '',
          incremental: '',
          line1Number: '',
          manufacturer: '',
          mmsUAProfUrl: '',
          mmsUserAgent: '',
          model: '',
          netWorkType: '',
          networkCountryIso: '',
          networkOperatorName: '',
          networkRoaming: '',
          os: '',
          phoneType: '',
          product: '',
          radio: '',
          releaseVersion: '',
          releaseVersionId: '',
          sdk: '',
          sdkInt: '',
          serial: '',
          simCountryIso: '',
          simOperator: '',
          simOperatorName: '',
          simSerialNumber: '',
          simState: '',
          subscriberId: '',
          surfType: '',
          tags: '',
          time: '',
          timeStr: '',
          totalDataSize: '',
          totalMemorySize: '',
          tuser: '',
          type: '',
          udid: '',
          updateTime: '',
          voiceMailAlphaTag: '',
          voiceMailNumber: ''
        },
        iosHardwareInfo: {
          availabledevicecapacity: '',
          batterylevel: '',
          bluetooth: '',
          bluetoothmac: '',
          buildversion: '',
          carriersettingsversion: '',
          cellulartechnology: '',
          currentcarriernetwork: '',
          currentmcc: '',
          currentmnc: '',
          datalink: '',
          dataroamingenabled: '',
          devicecapacity: '',
          devicename: '',
          hotspot: '',
          iccid: '',
          imei: '',
          isroaming: '',
          location: '',
          meid: '',
          model: '',
          modelname: '',
          modemfirmwareversion: '',
          osversion: '',
          phonenumber: '',
          productname: '',
          rom: '',
          root: '',
          serialnumber: '',
          simcarriernetwork: '',
          simmcc: '',
          simmnc: '',
          simulatlocation: '',
          subscribercarriernetwork: '',
          subscribermcc: '',
          subscribermnc: '',
          surftype: '',
          udid: '',
          updatetime: '',
          usb: '',
          vm: '',
          voiceroamingenabled: '',
          wifi: '',
          wifimac: ''
        },
        androidSoftwareInfo: {
          appCacheSize: '',
          appDataSize: '',
          appName: '',
          appSize: '',
          askStatus: '',
          firstInstallTime: '',
          id: '',
          isSystem: '',
          lastUpdateTime: '',
          lastUpdateTimeStr: '',
          packageName: '',
          purgeStatus: '',
          scanScore: '',
          udid: '',
          updateTime: '',
          versionCode: '',
          versionName: ''
        },
        assistantAppStatus: '',
        belong: '',
        brand: '',
        deviceConIMStatus: {
          conStatus: '',
          id: '',
          lastLoginTime: '',
          lastUpdateTime: '',
          udid: '65e39a0131d78f1983c785c7e60d70e7561a2d9d'
        },
        createTime: '',
        deviceGroup: {
          fullName: ''
        },
        deviceOs: {
          id: '',
          osType: ''
        },
        enrollmentTime: '',
        hardwareUpdateTime: '',
        id: '',
        installed: '',
        isActive: '',
        isJailBreak: '',
        isViolation: '',
        mode: '',
        model: '',
        signLongOffline: '',
        signStatus: '',
        status: '',
        bindStatus: '',
        surfType: '',
        uninstallCode: '',
        violationreason: ''
      },
      user: {
        osId: ''
      },
      clientInfo_ios: '',//ios客户端版本
      // 农行新增
      deviceInfo_bank: {},
      hardwareInfo_bank: {},
      deviceInfo_bank_ios: {}, //
    }
  },
  created() {
    // let user = JSON.parse(window.sessionStorage.getItem("user"));
    // this.getlist(user);
  },
  mounted() {
    setTimeout(() => {
      let user = JSON.parse(window.sessionStorage.getItem('user'))
      this.user = user
      this.getlist(user)
    }, 1000)
  },

  methods: {
    async getlist(user) {
      var param = {
        deviceId: user.id.toString(),
        osType: user.osId==30?'Windows':'Linux',
        deviceUDID: user.deviceUDID
      }
      console.log(param,'----------')
      const res = await this.$axios.post(
        '/httpServe/device/baseDeviceInfo',
        param,
        true
      )
      this.form = res.data
      console.log(this.form, 'Windows详情新增字段')

      if (res.data == null) {
        this.form = {}
      }
      if (res.data.windowsHardwareInfo) {
        if (res.data.windowsHardwareInfo.changeHardwareInfo) {
          this.deviceInfo_bank = Object.assign(
            eval('(' + res.data.windowsHardwareInfo.changeHardwareInfo + ')')
          )
        }
        if (res.data.windowsHardwareInfo.hardwareInfo) {
          this.hardwareInfo_bank = Object.assign(
            eval('(' + res.data.windowsHardwareInfo.hardwareInfo + ')')
          )
          console.log(this.hardwareInfo_bank.OS, '  this.hardwareInfo_bank')
        }
      }
      // // // //内存使用数
      // if (res.data.windowsHardwareInfo.availmemorysize != null) {
      //   var availmemorysize =
      //     ((this.form.windowsHardwareInfo.availmemorysize /
      //       1024 /
      //       1024 /
      //       1024) *
      //       10) /
      //     10.0
      //   var num = new Number(availmemorysize)
      //   // 内存总条数
      //   var totalmemorysize =
      //     ((this.form.windowsHardwareInfo.totalmemorysize /
      //       1024 /
      //       1024 /
      //       1024) *
      //       10) /
      //     10.0
      //   var newnum = new Number(totalmemorysize)
      //   // 内存使用率
      //   this.memoryUsage = this.$common.getPercent(num, newnum)
      //   this.form.windowsHardwareInfo.availmemorysize =
      //     num.toFixed('1') + 'GB/' + newnum.toFixed('1') + 'GB'
      //   // console..log(this.form.androidHardwareInfo.availmemorysize, '内存使用')
      // } else if (res.data.windowsHardwareInfo.availmemorysize == null) {
      //   this.form.windowsHardwareInfo.availmemorysize =
      //     '0.0' + 'GB/' + '0.0' + 'GB'
      // }
      // // 存储使用
      // if (res.data.windowsHardwareInfo.availdatasize != null) {
      //   // 存储使用
      //   var availdatasize =
      //     ((this.form.windowsHardwareInfo.availdatasize /
      //       1024 /
      //       1024 /
      //       1024) *
      //       10) /
      //     10.0
      //   var avail = new Number(availdatasize)
      //   // 存储总数
      //   var totaldatasize =
      //     ((this.form.windowsHardwareInfo.totaldatasize /
      //       1024 /
      //       1024 /
      //       1024) *
      //       10) /
      //     10.0
      //   var totalnum = new Number(totaldatasize)
      //   this.form.windowsHardwareInfo.availdatasize =
      //     avail.toFixed('1') + 'GB/' + totalnum.toFixed('1') + 'GB'
      //   // console..log(this.form.androidHardwareInfo.availdatasize, '存储使用')
      //   // this.$forceUpdate()
      // } else if (res.data.windowsHardwareInfo.availdatasize == null) {
      //   this.form.windowsHardwareInfo.availdatasize =
      //     '0.0' + 'GB/' + '0.0' + 'GB'
      // }
    }
  }
}
</script>

<style lang="scss" scoped>.el-form-item {
  margin-bottom: 0px !important;
}

.el-form-item__label {
  color: #000000 !important;
}

.wrap .group .msg_l0 {
  color: #807e7e !important;
}</style>
<style></style>
