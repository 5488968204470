<template>
  <div>
    <!-- 查询框 -->
    <el-row style="height:70px;">
      <el-col :span="24">
        <el-input v-model="chooseTable" size="mini" type="text" style="width:200px;" placeholder="请输入部门名称" @click="onChoose" @keyup.enter.native="onChoose">
          <i @click="onChoose" slot="suffix" class="el-input__icon el-icon-search" style="cursor: pointer;"></i>
        </el-input>
      </el-col>
      <el-col :span="24" style="line-height:28px;margin-top:4px;">
        <div style="fontSize:12px;float: left;">已选择：{{ num }}</div>
        <el-button type="primary" size="mini" style="float: right;display: inline-block">全部删除</el-button>
      </el-col>
    </el-row>
    <el-row :gutter="16" class="mt15" style="position: relative; margin-left: 0px; margin-right: 2px">
      <div @click="maskLayer" class="callenia" style="
                width: 48px;
                height: 32px;
                background: rgba(255, 255, 255, 0.1);
                position: absolute;
                top: 0px;
                left: 0px;
                z-index: 9999;
                font-size: 12px;
              ">
        <i class="el-icon-caret-bottom" style="
                  position: absolute;
                  top: 8px;
                  left: 24px;
                  color: #969696;
                  font-size: 14px;
                "></i>
      </div>
      <el-table stripe ref="multipleTable" fit class="sort_table" tooltip-effect="dark" :data="rightDataArray" style="width:99%" :header-cell-style="{ background: '#f7f8fa', color: '#606266' }">
        <el-table-column type="selection"> </el-table-column>
        <el-table-column prop="" label="部门名称" width="120">
        </el-table-column>
        <el-table-column prop="" label="上级部门"> </el-table-column>
      </el-table>
    </el-row>
    <pagination style="margin-right:10px;" :page="page.currentPage" :limit="page.pageSize" :total="page.total" :pageSizes="[10, 20, 30, 40]" v-on:handleChildGetList="handleParentGetList"></pagination>
    <!-- 全选弹出框 -->
    <ul class="tableCheckBox" v-show="isCheckShow" @mouseleave="leave">
      <li @click="curSelection()">当前页全选</li>
      <!-- <li @click="toggleAllSelection()">所有页全选</li> -->
      <li @click="toggleSelection()">取消全选</li>
    </ul>
  </div>
</template>

<script>
import pagination from '@/components/pagination/page.vue'
export default {
  components: { pagination },
  props: {
    rightObj: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      isCheckShow: false, // 全选弹框
      checked: false, // 全选框双向绑定
      num: '', //已勾选数据
      chooseTable: '', //查询已选中查询
      rightDataArray: [], //表格数据
      page: {
        currentPage: 1,
        pageSize: 10,
        orderColume: '',
        orderRule: '',
        total: 0
      }
    }
  },
  created() {},
  mounted() {},
  methods: {
    //全选遮罩层
    maskLayer() {
      this.isCheckShow = true
    },
    // 鼠标移出弹框
    leave() {
      this.isCheckShow = false
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.page.currentPage = page
      this.page.pageSize = limit
      // // 调用查询方法
      this.onChoose()
    },
    //已选中查询
    onChoose() {}
  }
}
</script>
<style lang="scss" scoped>
//全选弹框
.tableCheckBox {
  border: 1px solid #1e89e5;
  border-radius: 3px;
  width: 80px;
  background-color: #fff;
  position: absolute;
  top: 105px;
  left: 52%;
  z-index: 999;
  // background:rgba(255, 255, 255,.1)
  li {
    margin-top: -1px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #575656;
    cursor: pointer;
    font-weight: 400;
    font-family: Microsoft YaHei;
    font-size: 12px;
    list-style: none !important;
    div {
      display: inline-block;
    }
  }
  li:hover {
    color: #d38a08;
    text-decoration: underline;
  }
}
// 暂无数据
::v-deep .el-table__empty-block {
  width: 27% !important;
}
</style>
