<template>
  <div style="display: flex; height: 700px">
    <div style="margin-top: 10px">
      <el-form ref="form" :model="form" label-width="190px">
        <!-- 屏幕分辨率 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.Resolution')+'：'" v-if="user.osId == 10">
          <el-form-item v-if="form.hardwareInfo">
            {{ form.hardwareInfo.screen }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- IMEI -->
        <el-form-item label="IMEI：" v-if="user.osId == 10">
          <el-form-item v-if="form.imei">
            {{ form.imei }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- MEID -->
        <el-form-item label="MEID：" v-if="user.osId == 10">
          <el-form-item v-if="form.meid">
            {{ form.meid }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- ICCID -->
        <el-form-item label="ICCID：" v-if="user.osId == 10">
          <el-form-item v-if="form.iccid">
            {{ form.iccid }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- SIM国家代码 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.SIMcountrycode')+'：'" v-if="user.osId == 10">
          <el-form-item v-if="form.simmcc">
            {{ form.simmcc }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- 当前运营商网络 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.Currentoperator')+'：'" v-if="user.osId == 10">
          <el-form-item v-if="form.currentcarriernetwork">
            {{ form.currentcarriernetwork }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- 当前网络运营商 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.Networkoperator')+'：'" v-if="user.osId == 10">
          <el-form-item v-if="form.simcarriernetwork">
            {{ form.simcarriernetwork }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- 网络运营商代码 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.OperatorCode')+'：'" v-if="user.osId == 10">
          <el-form-item v-if="form.subscribermnc">
            {{ form.subscribermnc }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- 运营商设置版本 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.SettingsVersion')+'：'" v-if="user.osId == 10">
          <el-form-item v-if="form.carriersettingsversion">
            {{ form.carriersettingsversion }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- 网络运营商国家代码 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.Countrycode')+'：'" v-if="user.osId == 10">
          <el-form-item v-if="form.subscribermcc">
            {{ form.subscribermcc }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- 手机制式 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.Gsm')+'：'" v-if="user.osId == 20">
          <el-form-item v-if="form.phonetype">
            {{ form.phonetype }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- 屏幕分辨率 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.Resolution')+'：'" v-if="user.osId == 20">
          <el-form-item v-if="hardwareInfo_bank">
            {{ hardwareInfo_bank.screen }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- 网络类型 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.Network')+'：'" v-if="user.osId == 20">
          <el-form-item v-if="form.networktype">
            {{ form.networktype }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- 设备参数 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.Deviceparameter')+'：'" v-if="user.osId == 20">
          <el-form-item v-if="form.device">
            {{ form.device }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- 手机厂商 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.Phonefirm')+'：'" v-if="user.osId == 20">
          <el-form-item v-if="form.product">
            {{ form.product }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- 硬件名称 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.HardwareName')+'：'" v-if="user.osId == 20">
          <el-form-item v-if="form.hardware">
            {{ form.hardware }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- 软件版本号 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.Softwareversion')+'：'" v-if="user.osId == 20">
          <el-form-item v-if="form.devicesoftwareversion">
            {{ form.devicesoftwareversion }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- 手机用户ID -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.MobileUserID')+'：'" v-if="user.osId == 20">
          <el-form-item v-if="form.subscriberid">
            {{ form.subscriberid }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- 硬件制造商 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.Hardwaremanufacturer')+'：'" v-if="user.osId == 20">
          <el-form-item v-if="form.manufacturer">
            {{ form.manufacturer }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- 系统定制商 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.SystemCustomizer')+'：'" v-if="user.osId == 20">
          <el-form-item v-if="form.brand">
            {{ form.brand }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- 硬件串行号 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.Hardwareserial')+'：'" v-if="user.osId == 20">
          <el-form-item v-if="form.serial">
            {{ form.serial }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- 语音信息号码 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.Voicemessage')+'：'" v-if="user.osId == 20">
          <el-form-item v-if="form.voicemailnumber">
            {{ form.voicemailnumber }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- 网络运营商名称 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.OperatorName')+'：'" v-if="user.osId == 20">
          <el-form-item v-if="form.networkoperatorname">
            {{ form.networkoperatorname }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- 网络供应商国别 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.Suppliercountry')+'：'" v-if="user.osId == 20">
          <el-form-item v-if="form.networkcountryiso">
            {{ form.networkcountryiso }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- 基站位置 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.Basestation')+'：'" v-if="user.osId == 20">
          <el-form-item v-if="telephoneinfo_bank.cellCode">
            {{ telephoneinfo_bank.cellCode }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- 基站编号 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.Basestationnumber')+'：'" v-if="user.osId == 20">
          <el-form-item v-if="telephoneinfo_bank.cellID">
            {{ telephoneinfo_bank.cellID }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
      </el-form>
    </div>

    <div style="margin-top: 10px">
      <el-form ref="form" :model="form" label-width="300px">
        <!-- 设备序列号 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.Equipmentsernumber')+'：'" v-if="user.osId == 10">
          <el-form-item v-if="form.serialnumber">
            {{ form.serialnumber }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- 网络运行商 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.Networkoperator')+'：'" v-if="user.osId == 10">
          <el-form-item v-if="form.cellulartechnology">
            {{ form.cellulartechnology }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- 设备固件版本 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.Devicefirmware')+'：'" v-if="user.osId == 10">
          <el-form-item v-if="form.modemfirmwareversion">
            {{ form.modemfirmwareversion }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- 蓝牙物理地址 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.Physicaladdress')+'：'" v-if="user.osId == 10">
          <el-form-item v-if="form.bluetoothmac">
            {{ form.bluetoothmac }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- 注册的运营商 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.Registeredoperator')+'：'" v-if="user.osId == 10">
          <el-form-item v-if="form.subscribercarriernetwork">
            {{ form.subscribercarriernetwork }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- 是否允许漫游 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.Allowroaming')+'：'" v-if="user.osId == 10">
          <el-form-item v-if="form.isroaming">
            {{ form.isroaming }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- WiFi物理地址 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.WiFiphysicaladdress')+'：'" v-if="user.osId == 10">
          <el-form-item v-if="form.wifimac">
            {{ form.wifimac }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- SIM运营商代码 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.SIMoperatorcode')+'：'" v-if="user.osId == 10">
          <el-form-item v-if="form.simmnc">
            {{ form.simmnc }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- CPU架构 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.CPUarchitecture')+'：'" v-if="user.osId == 10">
          <el-form-item>
            {{'ARM'}}
          </el-form-item>
        </el-form-item>

        <!-- 主板 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.Motherboard')+'：'" v-if="user.osId == 20">
          <el-form-item v-if="form.board">
            {{ form.board }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- 版本 -->
        <el-form-item :label="$t('public.Version')+'：'" v-if="user.osId == 20">
          <el-form-item v-if="form.releaseversionid">
            {{ form.releaseversionid }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- 是否有SIM卡 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.HaveaSIMcard')+'：'" v-if="user.osId == 20">
          <el-form-item v-if="form.hasicccard">
            {{ form.hasicccard == 1 ? $t('DeviceManage.Device.Hardware.Correct') : $t('DeviceManage.Device.Hardware.Deny') }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- SIM卡国别 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.SIMcardcountry')+'：'" v-if="user.osId == 20">
          <el-form-item v-if="form.simcountryiso">
            {{ form.simcountryiso }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- SIM卡状态 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.SIMcardstatus')+'：'" v-if="user.osId == 20">
          <el-form-item v-if="form.simstate">
            <!-- <el-form-item v-if="form.simstate">
              form.simstate
            </el-form-item> -->
            <el-form-item v-if="form.simstate == 0">
              {{$t('DeviceManage.Device.Unknown')}}
            </el-form-item>
            <el-form-item v-if="form.simstate == 1">
              {{$t('DeviceManage.Device.Hardware.NoSIMcard')}}
            </el-form-item>
            <el-form-item v-if="form.simstate == 2">
              {{$t('public.Lock')}}
            </el-form-item>
            <el-form-item v-if="form.simstate == 3">
              {{$t('public.Lock')}}
            </el-form-item>
            <el-form-item v-if="form.simstate == 4">
              {{$t('public.Lock')}}
            </el-form-item>
            <el-form-item v-if="form.simstate == 5">
              {{$t('DeviceManage.Device.Hardware.Good')}}
            </el-form-item>
            <el-form-item v-if="form.simstate == 6">
              {{$t('DeviceManage.Device.Hardware.Notready')}}
            </el-form-item>
            <el-form-item v-if="form.simstate == 7">
              {{$t('DeviceManage.Device.Hardware.Error')}}
            </el-form-item>
            <el-form-item v-if="form.simstate == 8">
              {{$t('DeviceManage.Device.Hardware.Error')}}
            </el-form-item>
            <el-form-item v-if="form.simstate == 9">
              {{$t('DeviceManage.Device.Hardware.Islimited')}}
            </el-form-item>
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>

        </el-form-item>

        <!-- SIM卡序列号 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.SIMcardserial')+'：'" v-if="user.osId == 20">
          <el-form-item v-if="form.simserialnumber">
            {{ form.simserialnumber }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- SIM卡供应商 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.SIMcardsupplier')+'：'" v-if="user.osId == 20">
          <el-form-item v-if="form.simoperatorname">
            {{ form.simoperatorname }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- BUILD时间 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.BUILDtime')+'：'" v-if="user.osId == 20">
          <el-form-item v-if="form.time">
            {{ form.time }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- BUILD描述标签 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.BUILDdescription')+'：'" v-if="user.osId == 20">
          <el-form-item v-if="form.tags">
            {{ form.tags }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- CPU使用率 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.CPUusagerate')+'：'" v-if="user.osId == 20">
          <el-form-item v-if="deviceInfo_bank.cpuRate">
            {{ deviceInfo_bank.cpuRate +'%'}}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- CPU型号 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.CPUmodel')" v-if="user.osId == 20">
          <el-form-item v-if="hardwareInfo_bank.cpu">
            {{ hardwareInfo_bank.cpu }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- CPU架构 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.CPUarchitecture')+'：'" v-if="user.osId == 20">
          <el-form-item v-if="form.cpuabi">
            {{ form.cpuabi }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- CPU架构2 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.CPUarchitecture2')+'：'" v-if="user.osId == 20">
          <el-form-item v-if="form.cpuabi2">
            {{ form.cpuabi2 }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- 手机设备ID（IMEI） -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.MobileDeviceID')+'：'" v-if="user.osId == 20">
          <el-form-item v-if="form.deviceid">
            {{ form.deviceid }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- 用户可见的版本字符串 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.Visibleversionstring')+'：'" v-if="user.osId == 20">
          <el-form-item v-if="form.releaseversion">
            {{ form.releaseversion }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- WIFI信号强度 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.WIFIsignalstrength')+'：'" v-if="user.osId == 20">
          <el-form-item v-if="telephoneinfo_bank.wifiSignalLevel">
            {{ telephoneinfo_bank.wifiSignalLevel }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

      </el-form>
    </div>
    <div style="margin-top: 10px">
      <el-form ref="form" :model="form" label-width="300px">
        <!-- 网卡MAC -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.NetworkcardMAC')+'：'" v-if="user.osId == 20">
          <el-form-item v-if="hardwareInfo_bank.netMac">
            {{ hardwareInfo_bank.netMac }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- 蓝牙MAC -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.BluetoothMAC')+'：'" v-if="user.osId == 20">
          <el-form-item v-if="hardwareInfo_bank.bluetoothMac">
            {{ hardwareInfo_bank.bluetoothMac }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- IMSI -->
        <el-form-item label="IMSI：" v-if="user.osId == 20">
          <el-form-item v-if="hardwareInfo_bank.imsi">
            {{ hardwareInfo_bank.imsi }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- 按压力度(hPa) -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.Bypressure')+'：'" v-if="user.osId == 20">
          <el-form-item v-if="deviceInfo_bank.press">
            {{ deviceInfo_bank.press }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- 行为轨迹 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.Behaviortrace')+'：'" v-if="user.osId == 20">
          <el-form-item v-if="deviceInfo_bank.track">
            {{ deviceInfo_bank.track }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- 加速度传感器(m/s²) -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.Acceleratiosensor')+'：'" v-if="user.osId == 20">
          <el-form-item v-if="deviceInfo_bank.accelerated">
            {{ deviceInfo_bank.accelerated}}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- 温度传感器(℃) -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.Temperaturesensor')+'：'" v-if="user.osId == 20">
          <el-form-item v-if="deviceInfo_bank.temperature">
            {{ deviceInfo_bank.temperature}}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- 陀螺仪(rad/h) -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.Gyroscope')+'：'" v-if="user.osId == 20">
          <el-form-item v-if="deviceInfo_bank.gyro">
            {{ deviceInfo_bank.gyro }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  data() {
    return {
      form: {},
      user: {
        osId: ''
      },
      // 农行新增
      hardwareInfo_bank: {},
      telephoneinfo_bank: {},
      deviceInfo_bank: {}
    }
  },

  created() {},

  mounted() {
    let user = JSON.parse(window.sessionStorage.getItem('user'))
    this.user = user
    this.getlist(user)
  },

  methods: {
    async getlist(user) {
      var param = {
        deviceId: user.id,
        deviceUDID: user.deviceUDID,
        osType: user.osId == 20 ? 'Android' : 'iOS'
        // status: user.status,
      }
      // var param = {
      //   deviceId: '158',
      //   osType: 'Android',
      //   deviceUDID: '83c16aec64195b0b5b2410dc1c90121f2bd63f0c'
      // }
      // //// console..log(data);
      const res = await this.$axios.post(
        // "api/webHardware/deviceHardwareInfo.do",
        // 'httpServe/webHardware/deviceHardwareInfo',
        '/httpServe/webHardware/deviceHardwareInfo',
        param,
        true
      )
      console.log(res.data, 'telephoneInfo')

      if (user.osId == 20) {
        this.form = res.data
        console.log(res.data)
        // 字符串转对象
        if (res.data.hardwareInfo) {
          this.hardwareInfo_bank = Object.assign(
            eval('(' + res.data.hardwareInfo + ')')
          )
        }
        console.log(this.hardwareInfo_bank, 'hardwareInfo_bank2')
        if (res.data.deviceInfo) {
          this.deviceInfo_bank = Object.assign(
            eval('(' + res.data.deviceInfo + ')')
          )
        }
        console.log(
          // res.data.deviceInfo,
          this.deviceInfo_bank.accelerated,
          'this.deviceInfo_bank'
        )
        if (this.deviceInfo_bank) {
          // 加速度传感器
          if (this.deviceInfo_bank.accelerated) {
            var str = this.deviceInfo_bank.accelerated.split(',')
            this.deviceInfo_bank.accelerated = str.join('，')
          }
          //陀螺仪
          if (this.deviceInfo_bank.gyro) {
            var str = this.deviceInfo_bank.gyro.split(',')
            console.log(str, '陀螺仪')
            this.deviceInfo_bank.gyro = str.join('，')
          }
          //行为轨迹
          if (this.telephoneinfo_bank.track) {
            let arr1 = this.deviceInfo_bank.track.reduce(
              (total, currentValue) => {
                return total.concat(currentValue)
              }
            )
            this.deviceInfo_bank.track = JSON.parse(arr1).toString()
          }
        }
        if (res.data.telephoneInfo) {
          // var newObj
          this.telephoneinfo_bank = Object.assign(
            eval('(' + res.data.telephoneInfo + ')')
          )
        }
      } else if (user.osId == 10) {
        res.data.time = moment(parseInt(res.data.time)).format(
          'YYYY-MM-DD HH:mm:ss'
        )

        if (res.data.hardwareInfo) {
          res.data.hardwareInfo = Object.assign(
            eval('(' + res.data.hardwareInfo + ')')
          )
        }
        console.log(res.data.hardwareInfo)
        this.form = res.data
        console.log(this.form, '屏幕分片率')
      }
      if (res.data.time) {
        res.data.time = moment(parseInt(res.data.time)).format('YYYY-MM-DD')
        // YYYY-MM-DD HH:mm:ss

        this.form = res.data
        console.log(this.form, '屏幕分片率')
        this.form = res.data
      }

      //   //// console..log(moment(parseInt(res.data.time)).format('YYYY-MM-DD HH:mm:ss'),"llllll")

      // //// console..log(res.data, 'jjjjjj')
    }
  }
}
</script>

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 1px !important;
}

.el-form-item__label {
  color: #000000 !important;
}

.wrap .group .msg_l0 {
  color: #807e7e !important;
}
</style>
<style></style>
