<template>
  <div style="display: flex; height: 700px">
    <div style="margin-top: 10px">
      <el-form ref="form" :model="form" label-width="190px">
        <!-- 屏幕分辨率 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.Resolution')+'：'">
          <el-form-item v-if="hardwareInfo_bank.resolution">
            {{ hardwareInfo_bank.resolution }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- 蓝牙物理地址 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.Physicaladdress')+'：'">
          <el-form-item v-if="hardwareInfo_bank.bluetoothAddr">
            {{ hardwareInfo_bank.bluetoothAddr }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- WiFi IP -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.WiFiIP')+'：'">
          <el-form-item v-if="hardwareInfo_bank.wifiIp">
            {{ hardwareInfo_bank.wifiIp }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- WiFi MAC -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.WiFiMAC')+'：'">
          <el-form-item v-if="hardwareInfo_bank.wifiMac">
            {{ hardwareInfo_bank.wifiMac }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- CPU指令集 v6.3.0新增 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.CPUinstructionset')+'：'"  v-if="user.osId == 40">
          <el-form-item v-if="changeHardwareInfo_bank.cpuAbi">
            {{ changeHardwareInfo_bank.cpuAbi }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- 调制解调器固件版本 -->
        <!-- <el-form-item :label="$t('DeviceManage.Device.Hardware.Modemfirmwareversion')+'：'">
          <el-form-item v-if="form.modemfirmwareversion">
            {{ form.modemfirmwareversion }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item> -->
      </el-form>
    </div>
    <div style="margin-top: 10px">
      <el-form ref="form" :model="form" label-width="300px">
        <!-- 设备序列号 -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.Equipmentsernumber')+'：'">
          <el-form-item v-if="hardwareInfo_bank.serial">
            {{ hardwareInfo_bank.serial }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- 以太网 IP -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.EthernetIP')+'：'">
          <el-form-item v-if="hardwareInfo_bank.ethernetIp">
            {{ hardwareInfo_bank.ethernetIp }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- 以太网 MAC -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.EthernetMAC')+'：'">
          <el-form-item v-if="hardwareInfo_bank.ethernetMac">
            {{ hardwareInfo_bank.ethernetMac }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <!-- 网卡 MAC -->
        <el-form-item :label="$t('DeviceManage.Device.Hardware.EASdevice')+'：'">
          <el-form-item v-if="hardwareInfo_bank.ethernetMac">
            {{ hardwareInfo_bank.ethernetMac }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  data() {
    return {
      form: {},
      user: {
        osId: ''
      },
      // 农行新增
      hardwareInfo_bank: {},
      //v6.3.0 新增Linux  取CPU指令集
      changeHardwareInfo_bank:{},
      telephoneinfo_bank: {},
      deviceInfo_bank: {}
    }
  },

  created() {},

  mounted() {
    let user = JSON.parse(window.sessionStorage.getItem('user'))
    this.user = user
    this.getlist(user)
  },

  methods: {
    async getlist(user) {
      var param = {
        deviceId: user.id,
        deviceUDID: user.deviceUDID,
        osType: user.osId==30?'Windows':'Linux',
      }
      const res = await this.$axios.post(
        '/httpServe/webHardware/deviceHardwareInfo',
        param,
        true
      )
      this.form = res.data
      console.log(res.data.changeHardwareInfo,'v6.3.0 新增Linux 取CPU指令集')
      // 字符串转对象
      if (res.data.hardwareInfo) {
        this.hardwareInfo_bank = Object.assign(
          eval('(' + res.data.hardwareInfo + ')')
        )
      }
      console.log(this.hardwareInfo_bank, 'hardwareInfo_bank2')
      //v6.3.0 新增Linux  取CPU指令集
      if (res.data.changeHardwareInfo) {
        this.changeHardwareInfo_bank = Object.assign(
          eval('(' + res.data.changeHardwareInfo + ')')
        )
      }
      console.log(this.changeHardwareInfo_bank, 'changeHardwareInfo_bank2')
      if (res.data.deviceInfo) {
        this.deviceInfo_bank = Object.assign(
          eval('(' + res.data.deviceInfo + ')')
        )
      }
      console.log(
        // res.data.deviceInfo,
        this.deviceInfo_bank.accelerated,
        'this.deviceInfo_bank'
      )
      if (this.deviceInfo_bank) {
        // 加速度传感器
        if (this.deviceInfo_bank.accelerated) {
          var str = this.deviceInfo_bank.accelerated.split(',')
          this.deviceInfo_bank.accelerated = str.join('，')
        }
        //陀螺仪
        if (this.deviceInfo_bank.gyro) {
          var str = this.deviceInfo_bank.gyro.split(',')
          console.log(str, '陀螺仪')
          this.deviceInfo_bank.gyro = str.join('，')
        }
        //行为轨迹
        if (this.telephoneinfo_bank.track) {
          let arr1 = this.deviceInfo_bank.track.reduce(
            (total, currentValue) => {
              return total.concat(currentValue)
            }
          )
          this.deviceInfo_bank.track = JSON.parse(arr1).toString()
        }
      }
      if (res.data.telephoneInfo) {
        // var newObj
        this.telephoneinfo_bank = Object.assign(
          eval('(' + res.data.telephoneInfo + ')')
        )
      }
      if (res.data.time) {
        res.data.time = moment(parseInt(res.data.time)).format('YYYY-MM-DD')
        // YYYY-MM-DD HH:mm:ss

        this.form = res.data
        console.log(this.form, '屏幕分片率')
        this.form = res.data
      }

      //   //// console..log(moment(parseInt(res.data.time)).format('YYYY-MM-DD HH:mm:ss'),"llllll")

      // //// console..log(res.data, 'jjjjjj')
    }
  }
}
</script>

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 1px !important;
}

.el-form-item__label {
  color: #000000 !important;
}

.wrap .group .msg_l0 {
  color: #807e7e !important;
}
</style>
<style></style>
