<template>
  <div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
      style="margin-top: 10px; margin-left: 3%">
      <el-form-item :label="$t('DeviceManage.Device.DeviceImport.Documents') + '：'">
        <el-input size="small" disabled v-model="ruleForm.filesName" style="width: 220px"></el-input>
        <el-upload size="small" action="#" :auto-upload="false" :show-file-list="false" class="el-button uploadBox"
          :on-change="changeUpload" :on-success="handleSuccess" :before-upload="beforeUpload" multiple accept=".xls,.xlsx"
          style="padding: 0; margin-left: 12px; border: none">
          <el-button size="small" type="warning" icon="el-icon-view"> {{ $t('DeviceManage.Device.DeviceImport.Choose') }}
          </el-button>
        </el-upload>
        <el-progress
          v-if="flagStatus"
          :percentage="percentage"
          :color="customColorMethod"
          style="width: 230px"
        ></el-progress>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" @click="submit">{{ $t('DeviceManage.Device.DeviceImport.DownloadTemplate') }}
        </el-button>
      </el-form-item>
      <!-- 0-自动审批  1-手动审批 :true-label=1 :false-label=0-->
      <el-form-item :label="$t('DeviceManage.Device.ManualApproval')" prop="">
        <el-checkbox @change="handleCheckedCitiesChange" v-model="ruleForm.approvalType" true-label='1'
          false-label='0'><span
            style="color: #606266">{{ $t('DeviceManage.Device.ManualApprovalContent') }}</span></el-checkbox>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.Device.DeviceImport.DeviceList') + '：'" prop="fileName">
        <el-table size="small" stripe fit ref="multipleTable" tooltip-effect="dark" class="tableClass"
          :data="usertableData" :row-style="iRowStyle" :cell-style="iCellStyle" :header-row-style="iHeaderRowStyle"
          style="width: 99%" :header-cell-style="{ background: '#f7f8fa', color: '#606266' }">
          <el-table-column prop="code" :label="$t('DeviceManage.Device.DeviceImport.AllowImport')"
            :show-overflow-tooltip="true" width="120">
            <template v-slot="{ row }">
              <template v-if="row.code == -1"><span
                  style="color: #ff0000">{{ $t('DeviceManage.Device.Hardware.Deny') }}</span>
              </template>
              <template v-if="row.code == 0">
                <span style="color: #008000"> {{ $t('DeviceManage.Device.Hardware.Correct') }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column prop="deviceName" :label="$t('public.DeviceName')" :show-overflow-tooltip="true" width="120">
          </el-table-column>
          <el-table-column prop="loginName" :label="$t('public.Username')" :show-overflow-tooltip="true" width="120">
          </el-table-column>
          <el-table-column prop="groupFullName" :label="$t('public.Section')" :show-overflow-tooltip="true" width="220">
            <template v-slot="{ row }">
              <span v-if="row.groupFullName">{{ row.groupFullName.substr(groupFullNameShowByIndex) }}</span>
            </template>
          </el-table-column>

          <el-table-column prop="osType" :label="$t('public.OperatingSystem')" :show-overflow-tooltip="true" width="120">
          </el-table-column>
          <!-- v6.2.0 新增 IMEI/UDID/网卡MAC-->
          <el-table-column prop="deviceId" :label="$t('DeviceManage.Device.DeviceImport.IMEIUDIDMAC')" :show-overflow-tooltip="true" width="260">
          </el-table-column>
          <el-table-column prop="belong" :label="$t('DeviceManage.Device.Equipmentownership')"
            :show-overflow-tooltip="true" width="120">
          </el-table-column>
          <el-table-column prop="message" :label="$t('DeviceManage.Device.DeviceImport.Notes')"
            :show-overflow-tooltip="true">
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" @click="submitForm('ruleForm')" v-show="btnSaveFlag">{{ $t('public.Save') }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
const Base64 = require('js-base64').Base64
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    ruleFormObj: {
      type: Object,
      // 对象或数组默认值必须从一个工厂函数获取
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      imgUrl: vueConfig.jqUrl.slice(0, vueConfig.jqUrl.length - 1), //下载模板
      // 导入
      flagStatus: false,
      percentage: 0,
      filename: '',
      fileName: '',
      form: {
        filename: ''
      },
      importUserInfo: [],
      btnSaveFlag: false, //导入按钮默认为空
      usertableData: [], //导入列表
      ruleForm: {
        cerName: '', //证书名称
        fileName: '', //证书上传
        cerPass: '', //证书密码
        cerAliasName: '', //证书别名
        cerAliasPass: '', //别名密码
        cerMemo: '', //证书简介
        size: '', //上传文件大小
        cerPath: '', //上传文件路径
        id: '',
        approvalType: '0',//0-自动审批  1-手动审批
      },
      groupFullNameShowByIndex: '',
      rules: {
        cerName: [
          { required: true, message: '请输入证书名称', trigger: 'blur' }
          // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" }
        ],
        fileName: [{ required: true, message: '请上传证书', trigger: 'blur' }],
        cerPass: [
          { required: true, message: '请输入证书密码', trigger: 'blur' }
        ]
      }
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() { },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.ruleForm = this.ruleFormObj
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      'groupFullNameShowByIndex'
    )
    // console.log(this.ruleForm.id);
  },
  beforeCreate() { }, // 生命周期 - 创建之前
  beforeMount() { }, // 生命周期 - 挂载之前
  beforeUpdate() { }, // 生命周期 - 更新之前
  updated() { }, // 生命周期 - 更新之后
  beforeDestroy() { }, // 生命周期 - 销毁之前
  destroyed() { }, // 生命周期 - 销毁完成
  activated() { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    handleCheckedCitiesChange(value) {
      // console.log(value,"value");
    },
    //校验表单
    validateForm() {
      let flag = null
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          flag = true
        } else {
          flag = false
        }
      })
      return flag
    },
    customColorMethod(percentage) {
      if (percentage < 30) {
        return "#909399";
      } else if (percentage < 70) {
        return "#e6a23c";
      } else {
        return "#67c23a";
      }
    },
    //文件上传
    async changeUpload(file, fileList) {
      this.flagStatus = true;
      this.percentage = 0;
      // 获取上传的文件
      var files = file
      this.filesName = files.name
      this.$nextTick(() => {
        this.$set(this.ruleForm, 'filesName', this.filesName)
      })
      // 判断上传的文件是否是 以apk结尾的apk文件
      if (
        files.name.substr(files.name.length - 3) === 'xls' ||
        files.name.substr(files.name.length - 4) === 'xlsx'
      ) {
        let formData = new FormData()
        var name = file.raw['name']
        // .substring(0, name.lastIndexOf("."));
        this.form['filename'] = name
        formData.append('file', file.raw)
        formData.append('fileId', 'file')
        const res = await this.$axios.upload(
          '/httpServe/device/extractFile',
          formData,
          this.updateProgress,
          false
        )
        if(res.data.code == 200){
          this.flagStatus = false;
          this.$message({
            message: this.$t("public.ImportSuccessful"),
            type: 'success',
            duration: 3000
          })
        }
        if(res.data.code == 101){
          this.flagStatus = false;
          this.$message({
            message: this.$t("DeviceManage.Device.DeviceImport.message"),
            type: 'error',
            duration: 3000
          })
          return;
        }
        this.usertableData = res.data.data.list
        //最新6.1.1版本
        var userName = this.usertableData.map((item) => {
          return item.code;
        })
        const allZeros = userName.every(item => item === '-1')
        console.log(allZeros, "allZeros");
        if (allZeros) {
          this.flagStatus = false;
          this.btnSaveFlag = false
        } else {
          this.btnSaveFlag = true
        }
      } else {
        console.log(1111111111,'11111111111111111111');
        this.$nextTick(() => {
          this.$set(this.ruleForm, 'filesName', '')
        })
        this.$message({
          message: this.$t('DeviceManage.Device.DeviceImport.Pleaseupload'),
          type: 'error',
          duration: 100
        })
        this.flagStatus = false;
        this.btnSaveFlag = false
        this.usertableData = ''
        // this.titleInfo = "请上传xls或xlsx文件";
      }
    },
    updateProgress(e) {
      //e为回调回来的参数 通过进行和total的值来进行进度
      this.percentage = parseInt((e.loaded /e.total)* 100)
    },
    handleSuccess(response, file, fileList) {
      this.$refs.form.clearFiles()
      this.$notify({
        title: this.$t('DeviceManage.Device.DeviceImport.Uploadsuccessful'),
        type: 'success',
        duration: 2500
      })
    },
    beforeUpload(file) {
      let isLt2M = true
      isLt2M = file.size / 1024 / 1024 < 100
      if (!isLt2M) {
        this.loading = false
        this.$message.error(this.$t('DeviceManage.Device.DeviceImport.Cannotexceed'))
      }
      console.log(file.name)
      this.filename = file.name
      return isLt2M
    },
    async submit() {
      var params = {
        // type: "protected",
        path: 'public/模板文档/设备模板.xls'
      }
      // console.log(params);
      const res = await this.$axios.post(
        '/httpServe/sysFile/fileDown',
        params,
        true
      )
      // console.log(res);
      // let url = Base64.decode(res.data);
      let url = Base64.decode(res.data)
      url = this.imgUrl + url
      // console.log(url, "-----url");
      location.href = url
    },

    //保存按钮
    async submitForm() {
      let arr = []
      this.usertableData.forEach((item) => {
        console.log(item,'item保存按钮');
        if (item.code == 0) {
          if(item.osType =='Android'){
            let osAndType='20'
            item.osType=osAndType
          }else if(item.osType =='iOS'){
            let osidType='10'
            item.osType=osidType
          }else if(item.osType =='Windows'){
            let osWinType='30'
            item.osType=osWinType
          }else if(item.osType =='Linux'){
            let osLinuxType='40'
            item.osType=osLinuxType
          }
          arr.push({
            // deviceName: item.deviceName,
            // loginName: item.deviceName,
            // osType: item.osType,
            // imei: "edae006eea43f52c3cc10574e82504b61fbe9d5922344",
            // belong: 2,
            belong: item.belong=='企业' ? '1' : '2',
            // code: "0",
            imei: item.deviceId,
            deviceName: item.deviceName,
            groupFullName: item.groupFullName,
            loginName: item.loginName,
            // message: "OK",
            osType: item.osType,
          })
        }
      })
      var param = {
        // approvalType: this.ruleForm.approvalType,//0-自动审批  1-手动审批
        approvalType: this.ruleForm.approvalType == undefined ? '0' : '1',//0-自动审批  1-手动审批
        importDevice: arr
      }
      console.log(param, '导入param保存')
      const res = await this.$axios.post(
        '/httpServe/device/import',
        param,
        true
      )
      console.log(res, '----')
      if (res.code == 101) {
        // this.titleInfo = "保存失败，该设备名称已存在";
        // this.visibaelFlag = true;
        this.$message({
          message: res.message,
          type: "error",
          offset: 100,
        });
      }
      if (res.data == 0) {
        this.flag = 1
        this.btnSaveFlag = false
        this.usertableData = ''
        // this.visibaelFlag = true;
        // this.dataList(this.id);
        // this.titleInfo = "保存成功";
        this.$message({
          message: this.$t('public.SuccessfullySaved'),
          type: 'success',
          duration: 100
        })
      }
      var flag = 1
      this.$emit('sunline', flag)
    },
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return 'height:30px'
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return 'height:30px'
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return 'padding:3px 0px'
    }
  }
}
</script>

<style lang="scss" scoped>//@import url(); 引入公共css类
.uploadBox {
  border: 0px !important;
}</style>
