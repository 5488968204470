<template>
  <div style="display: flex;">
    <div style="margin-top: 20px">
      <el-form ref="form" :model="form" label-width="190px">
        <!-- 锁定设备 -->
        <el-form-item :label="$t('DeviceManage.Device.Lockingdevice')">
          <span>{{$t('DeviceManage.Device.Trajectory.OPERATIONLOCK')}}</span>
          <p style="margin-left: 2%">
            <el-button type="primary" size="mini" @click="lockDevice">{{$t('DeviceManage.Device.Lockingdevice')}}</el-button>
          </p>
        </el-form-item>

        <!-- 解锁设备 -->
        <el-form-item :label="$t('DeviceManage.Device.Unlockdevice')">
          <span>{{$t('DeviceManage.Device.Trajectory.Operationunlocking')}}</span>
          <p style="margin-left: 2%">
            <el-button type="primary" size="mini" @click="unLockDevice">{{$t('DeviceManage.Device.Unlockdevice')}}</el-button>
          </p>
        </el-form-item>

        <!-- <el-form-item label="锁定设备" v-if="user.osId == 10">
          <span>（此操作将把设备锁定，仅支持监督模式）</span>
          <p style="margin-left: 2%">
            <el-button type="primary" size="mini" @click="lockDevice">锁定设备</el-button>
          </p>
        </el-form-item>


        <el-form-item label="解锁设备" v-if="user.osId == 10">
          <span>（此操作将把锁定设备进行解锁，仅支持监督模式）</span>
          <p style="margin-left: 2%">
            <el-button type="primary" size="mini" @click="unLockDevice">解锁设备</el-button>
          </p>
        </el-form-item> -->

        <!-- 密码锁定 -->
        <el-form-item :label="$t('DeviceManage.Device.Trajectory.PasswordLock')" v-if="user.osId == 20">
          <span>{{$t('DeviceManage.Device.Trajectory.PassLockreset')}}</span>
          <p style="margin-left: 2%; display: flex;height:28px;line-height: 28px;">
            <el-button type="primary" size="mini" @click="password">{{$t('DeviceManage.Device.Trajectory.PasswordLock')}}</el-button>
            <el-input size="mini" v-model="input2" style="width: 40%; margin-left: 10px" :placeholder="$t('DeviceManage.Device.Trajectory.PlasPasslock')"></el-input>
          </p>
        </el-form-item>

        <!-- 清除密码 -->
        <el-form-item :label="$t('DeviceManage.Device.Trajectory.Clearpassword')" v-if="user.osId == 20">
          <span>{{$t('DeviceManage.Device.Trajectory.AppClearpass')}}</span>
          <p style="margin-left: 2%">
            <el-button type="primary" size="mini" @click="clearpassword">{{$t('DeviceManage.Device.Trajectory.Clearpassword')}}</el-button>
          </p>
        </el-form-item>

        <!-- 清除数据 -->
        <el-form-item :label="$t('DeviceManage.Device.Cleardata')">
          <span>{{$t('DeviceManage.Device.Trajectory.Clearbusiness')}}</span>
          <p style="margin-left: 2%">
            <el-button type="primary" size="mini" @click="Cleardata">{{$t('DeviceManage.Device.Cleardata')}}</el-button>
          </p>
        </el-form-item>

        <!-- 强制注销 -->
        <el-form-item :label="$t('DeviceManage.Device.Forcedcancell')">
          <span>{{$t('DeviceManage.Device.Trajectory.Compulsorywithdrawal')}}</span>
          <p style="margin-left: 2%">
            <el-button type="primary" size="mini" @click="Forcelogoff">{{$t('DeviceManage.Device.Forcedcancell')}}</el-button>
          </p>
        </el-form-item>

        <!-- 卸载码 -->
        <el-form-item :label="$t('DeviceManage.Device.Trajectory.Uninstallationcode')" v-if="user.osId == 20">
          <span>{{$t('DeviceManage.Device.Trajectory.Generatecode')}}</span>
          <p style="margin-left: 2%; display: flex;height:28px;line-height: 28px;">
            <el-button :type="type" size="mini" @click="uninstall" :disabled="disabled">{{$t('DeviceManage.Device.Trajectory.Generateuninstallationcode')}}</el-button>
            <el-input disabled size="mini" style="width: 30%; margin-left: 10px" v-model="form.uninstallCode" v-if="uninstallCode"></el-input>
          </p>
        </el-form-item>

        <!-- 设备丢失 -->
        <el-form-item :label="$t('DeviceManage.Device.Trajectory.Deviceloss')" v-if="user.osId == 10">
          <span>{{$t('DeviceManage.Device.Trajectory.MarkasMissing')}}</span>
          <p style="margin-left: 2%">
            <el-button type="primary" size="mini" @click="deviceLost">{{$t('DeviceManage.Device.Trajectory.Deviceloss')}}</el-button>
          </p>
        </el-form-item>
        <!-- <el-form-item label="恢复出厂" v-if="user.osId == 10">
          <span>（此操作将把设备恢复到出厂设置状态）</span>
          <p style="margin-left: 2%">
            <el-button type="primary" size="mini" @click="recovery">恢复出厂</el-button>
          </p>
        </el-form-item> -->
      </el-form>
    </div>
    <div style="margin-top: 20px">
      <el-form ref="form" :model="form" label-width="300px">
        <!-- 恢复出厂 -->
        <el-form-item :label="$t('DeviceManage.Device.Factoryrecovery')">
          <span>{{$t('DeviceManage.Device.Trajectory.RestoreFactory')}}</span>
          <p style="margin-left: 2%">
            <el-button type="primary" size="mini" @click="recovery">{{$t('DeviceManage.Device.Factoryrecovery')}}</el-button>
          </p>
        </el-form-item>

        <!-- 提取日志 -->
        <el-form-item :label="$t('DeviceManage.Device.Extractlog')">
          <span>{{$t('DeviceManage.Device.Trajectory.Operationlog')}}</span>
          <p style="margin-left: 2%">
            <el-button type="primary" size="mini" @click="extract">{{$t('DeviceManage.Device.Extractlog')}}</el-button>
          </p>
        </el-form-item>

        <!-- 安装应用 -->
        <!-- 安卓 -->
        <el-form-item :label="$t('DeviceManage.Device.Installapplica')" v-if="user.osId == 20">
          <span>{{$t('DeviceManage.Device.Trajectory.Silentinstall')}}</span>
          <p style="margin-left: 2%">
            <el-button type="primary" size="mini" @click="Install">{{$t('DeviceManage.Device.Installapplica')}}</el-button>
          </p>
        </el-form-item>
        <!-- ios -->
        <el-form-item :label="$t('DeviceManage.Device.Installapplica')" v-if="user.osId == 10">
          <span>{{$t('DeviceManage.Device.Trajectory.Pushinstall')}}</span>
          <p style="margin-left: 2%">
            <el-button type="primary" size="mini" @click="Install">{{$t('DeviceManage.Device.Installapplica')}}</el-button>
          </p>
        </el-form-item>

        <!-- 卸载应用 -->
        <!-- 安卓 -->
        <el-form-item :label="$t('DeviceManage.Device.Uninstallapplica')" v-if="user.osId == 20">
          <span>{{$t('DeviceManage.Device.Trajectory.Silentuninstall')}}</span>
          <p style="margin-left: 2%">
            <el-button type="primary" size="mini" @click="dump">{{$t('DeviceManage.Device.Uninstallapplica')}}</el-button>
          </p>
        </el-form-item>
        <!-- ios -->
        <el-form-item :label="$t('DeviceManage.Device.Uninstallapplica')" v-if="user.osId == 10">
          <span>{{$t('DeviceManage.Device.Trajectory.PushUninstall')}}</span>
          <p style="margin-left: 2%">
            <el-button type="primary" size="mini" @click="dumps">{{$t('DeviceManage.Device.Uninstallapplica')}}</el-button>
          </p>
        </el-form-item>

        <!-- 解除丢失 -->
        <!-- ios -->
        <el-form-item :label="$t('DeviceManage.Device.Trajectory.Releaseloss')" v-if="user.osId == 10">
          <span>{{$t('DeviceManage.Device.Trajectory.Losspatterns')}}</span>
          <p style="margin-left: 2%">
            <el-button type="primary" size="mini" @click="disableLost">{{$t('DeviceManage.Device.Trajectory.Releaseloss')}}</el-button>
          </p>
        </el-form-item>

        <!-- 设备关机 -->
        <!-- 安卓 -->
        <el-form-item :label="$t('DeviceManage.Device.Trajectory.Deviceshutdown')" v-if="user.osId == 20">
          <span>{{$t('DeviceManage.Device.Trajectory.Apphutdown')}}</span>
          <p style="margin-left: 2%">
            <el-button type="primary" size="mini" @click="shutdown">{{$t('DeviceManage.Device.Trajectory.Deviceshutdown')}}</el-button>
          </p>
        </el-form-item>

        <!-- 设备重启 -->
        <el-form-item :label="$t('DeviceManage.Device.Trajectory.Devicerestart')" v-if="user.osId == 20">
          <span>{{$t('DeviceManage.Device.Trajectory.Apprestart')}}</span>
          <p style="margin-left: 2%">
            <el-button type="primary" size="mini" @click="restart">{{$t('DeviceManage.Device.Trajectory.Devicerestart')}}</el-button>
          </p>
        </el-form-item>

        <!-- 设备丢失 -->
        <el-form-item :label="$t('DeviceManage.Device.Trajectory.Deviceloss')" v-if="user.osId == 20">
          <span>{{$t('DeviceManage.Device.Trajectory.ApprestartClient')}}</span>
          <p style="margin-left: 2%">
            <el-button type="primary" size="mini" @click="deviceLost">{{$t('DeviceManage.Device.Trajectory.Deviceloss')}}</el-button>
          </p>
        </el-form-item>
      </el-form>
    </div>
    <!-- 安装应用 -->
    <Eldialog :num="'450px'" @handleClose="handleClose" :title="$t('DeviceManage.Device.Installationapp.Installationapplication')" :btnTitle="$t('DeviceManage.Device.Installationapp.Install')" :dialogVisible="dialogVisible" @heightCustom="heightCustom" @determine="joinType" :flagbtn="true" :cancelBtn="true" :determineBtn="true">
      <div style="height: 450px">
        <el-input v-model="input" size="small" style="width: 200px; margin-bottom: 10px" :placeholder="$t('DeviceManage.Device.Installationapp.Pleaseenter')" @keyup.enter.native="searchDev">
          <i style="cursor: pointer" class="el-icon-search el-input__icon" slot="suffix" @click="searchDev">
          </i>
        </el-input>
        <el-table size="mini" ref="multipleTable" :data="gridData" stripe class="sort_table" tooltip-effect="dark" style="width: 99%" :header-cell-style="{ background: '#f7f8fa', color: '#606266' }" @selection-change="handleSelectionChange1" :default-sort="{ prop: 'e.lastupdatetime', order: 'descending' }" @sort-change="sort">
          <el-table-column type="selection" width="55" header-align="center" align="center"> </el-table-column>
          <el-table-column prop="appName" :show-overflow-tooltip="true" :label="$t('DeviceManage.Device.Installationapp.appName')" width="85">
          </el-table-column>
          <el-table-column prop="packageName" :show-overflow-tooltip="true" :label="$t('DeviceManage.Device.Installationapp.ApplicationID')" width="85">
          </el-table-column>
          <el-table-column prop="versionName" :show-overflow-tooltip="true" :label="$t('DeviceManage.Device.Installationapp.Edition')" width="85">
          </el-table-column>
          <el-table-column prop="installType" :show-overflow-tooltip="true" label="安装模式" width="85" v-if="user.osId == 20">
            <template v-slot="{ row }"> 
              <template v-if="row.installType == 0 || row.installType == null || row.installType == ''"> 普通安装 </template>
              <template v-else-if="row.installType == 1"> 安全域安装 </template>
            </template>
          </el-table-column>
          <el-table-column prop="lastupdatetime" :show-overflow-tooltip="true" :label="$t('public.ModificationTime')">
          </el-table-column>
        </el-table>
        <p style="margin-top: 10px">
          {{$t('DeviceManage.Device.Installationapp.Common')}}<b>{{ gridData.length }}</b>{{$t('DeviceManage.Device.Installationapp.Records')}}
        </p>
        <!-- <p>
          <el-button
            @click="joinType"
            style="margin-left: 540px"
            type="primary"
            size="small"
            >安装</el-button
          >
        </p> -->
      </div>
    </Eldialog>
    <!-- 20 安卓卸载应用 -->
    <Eldialog @handleClose="handleClose" :title="$t('DeviceManage.Device.Uninstallapp.Uninstallapplication')" :btnTitle="$t('DeviceManage.Device.Uninstallapp.Unload')" :dialogVisible="install" :flagbtn="true" :cancelBtn="true" :determineBtn="true" @determine="xiezai">
      <div style="height: 450px">
        <el-input v-model="input1" size="small" style="width: 200px; margin-bottom: 10px" :placeholder="$t('DeviceManage.Device.Installationapp.Pleaseenter')" @keyup.enter.native="searchDevs">
          <i style="cursor: pointer" class="el-icon-search el-input__icon" slot="suffix" @click="searchDevs">
          </i>
        </el-input>
        <el-table size="mini" ref="multipleTable" :data="gridData" stripe class="sort_table" tooltip-effect="dark" style="width: 99%" :header-cell-style="{ background: '#f7f8fa', color: '#606266' }" @selection-change="handleSelectionChange1" :default-sort="{ prop: 'e.lastupdatetime', order: 'descending' }" @sort-change="sort">
          <el-table-column type="selection" width="55" header-align="center" align="center"> </el-table-column>
          <el-table-column prop="appName" :show-overflow-tooltip="true" :label="$t('DeviceManage.Device.Installationapp.appName')" width="85">
          </el-table-column>
          <el-table-column prop="packageName" :show-overflow-tooltip="true" :label="$t('DeviceManage.Device.Installationapp.ApplicationID')" width="85">
          </el-table-column>
          <el-table-column prop="versionName" :show-overflow-tooltip="true" :label="$t('DeviceManage.Device.Installationapp.Edition')" width="85">
          </el-table-column>
          <el-table-column prop="installType" :show-overflow-tooltip="true" label="安装模式" width="85" v-if="user.osId == 20">
            <template v-slot="{ row }"> 
              <template v-if="row.installType == 0 || row.installType == null || row.installType == ''"> 普通安装 </template>
              <template v-else-if="row.installType == 1"> 安全域安装 </template>
            </template>
          </el-table-column>
          <el-table-column prop="lastUpdateTimeStr" :show-overflow-tooltip="true" :label="$t('public.ModificationTime')">
          </el-table-column>
        </el-table>
        <p style="margin-top: 10px">
          {{$t('DeviceManage.Device.Installationapp.Common')}}<b>{{ gridData.length }}</b>{{$t('DeviceManage.Device.Installationapp.Records')}}
        </p>
        <!-- <p>
          <el-button
            @click="xiezai"
            style="margin-left: 530px"
            type="primary"
            size="small"
            >卸载</el-button
          >
        </p> -->
      </div>
    </Eldialog>
    <!-- 10 ios卸载应用 -->
    <Eldialog @handleClose="handleClose" :title="$t('DeviceManage.Device.Uninstallapp.Uninstallapplication')" :btnTitle="$t('DeviceManage.Device.Uninstallapp.Unload')" :dialogVisible="installs" :flagbtn="true" :cancelBtn="true" :determineBtn="true" @determine="xiezai">
      <div style="height: 450px">
        <el-input
          v-model="input1"
          size="small"
          style="width: 200px; margin-bottom: 10px"
          :placeholder="$t('DeviceManage.Device.Installationapp.Pleaseenter')"
          @keyup.enter.native="searchDevss"
        >
          <i
            style="cursor: pointer"
            class="el-icon-search el-input__icon"
            slot="suffix"
            @click="searchDevss"
          >
          </i>
        </el-input>
        <el-table size="mini" ref="multipleTable" :data="gridData" stripe class="sort_table" tooltip-effect="dark" style="width: 99%" :header-cell-style="{ background: '#f7f8fa', color: '#606266' }" @selection-change="handleSelectionChange1" :default-sort="{ prop: 'e.createTime', order: 'descending' }" @sort-change="sort">
          <el-table-column type="selection" width="55" header-align="center" align="center"> </el-table-column>
          <el-table-column prop="name" :show-overflow-tooltip="true" :label="$t('DeviceManage.Device.Installationapp.appName')" width="120">
          </el-table-column>
          <el-table-column prop="identifier" :show-overflow-tooltip="true" :label="$t('DeviceManage.Device.Installationapp.ApplicationID')" width="150">
          </el-table-column>
          <el-table-column prop="shortVersion" :show-overflow-tooltip="true" :label="$t('DeviceManage.Device.Installationapp.Edition')" width="100">
          </el-table-column>
          <el-table-column prop="createTime" :show-overflow-tooltip="true" :label="$t('public.ModificationTime')">
          </el-table-column>
        </el-table>
        <p style="margin-top: 10px">
          {{$t('DeviceManage.Device.Installationapp.Common')}}<b>{{ gridData.length }}</b>{{$t('DeviceManage.Device.Installationapp.Records')}}
        </p>
        <!-- <p>
          <el-button
            @click="xiezai"
            style="margin-left: 530px"
            type="primary"
            size="small"
            >卸载</el-button
          >
        </p> -->
      </div>
    </Eldialog>
  </div>
</template>

<script>
import Eldialog from '@/components/elDialog'
// 弹框
import dialogInfo from '@/components/promptMessage/index'
import { log } from 'console'
export default {
  components: { Eldialog, dialogInfo },
  // props: {
  //   dictData: {
  //     type: Object,
  //     default: {},
  //   },
  // },
  data() {
    return {
      type: 'primary',
      dataTolCount: '', //分页
      input: '', //查询
      input1: '', //查询
      gridData: [],
      form: {
        // name: "11",
        user: {},
        uninstallCode: ''
      },
      user: {
        osId: ''
      },
      disabled: false,
      dialogVisible: false, //弹框组件默认--关闭
      install: false, //安卓弹框组件默认--关闭
      installs: false, //ios弹框组件默认--关闭
      flag: 1, // 显示当前 / 编辑页面
      isnlist: [],
      datal: [],
      input2: '',
      uninstallCode: false,
      Instpage: {
        orderColume: 'e.lastupdatetime', //排序字段
        orderRule: 'DESC' //排序规则ASC、DESC
      },
      editlist: []
    }
  },
  created() {},
  mounted() {
    let user = JSON.parse(window.sessionStorage.getItem('user'))
    this.user = user
    setTimeout(() => {
      console.log(this.user.osId)
    }, 3000)
    this.getlist(user)
  },

  methods: {
    // 排序
    sort(column) {
      this.queryObj.orderColume = column.prop
      console.log(column.order, column.prop)
      if (column.prop == 'd.deviceName') {
        if (column.order == 'ascending') {
          this.page.orderColume = 'd.deviceName'
          this.page.orderRule = 'ASC'
        } else {
          this.page.orderColume = 'd.deviceName'
          this.page.orderRule = 'DESC'
        }
      } else if (column.prop == 'u.loginName') {
        if (column.order == 'ascending') {
          this.page.orderColume = 'u.loginName'
          this.page.orderRule = 'ASC'
        } else {
          this.page.orderColume = 'u.loginName'
          this.page.orderRule = 'DESC'
        }
      } else if (column.prop == 'u.userName') {
        if (column.order == 'ascending') {
          this.page.orderColume = 'u.userName'
          this.page.orderRule = 'ASC'
        } else {
          this.page.orderColume = 'u.userName'
          this.page.orderRule = 'DESC'
        }
      } else if (column.prop == 'g.groupName') {
        if (column.order == 'ascending') {
          this.page.orderColume = 'g.groupName'
          this.page.orderRule = 'ASC'
        } else {
          this.page.orderColume = 'g.groupName'
          this.page.orderRule = 'DESC'
        }
      } else if (column.prop == 'd.osId') {
        if (column.order == 'ascending') {
          this.page.orderColume = 'd.osId'
          this.page.orderRule = 'ASC'
        } else {
          this.page.orderColume = 'd.osId'
          this.page.orderRule = 'DESC'
        }
      } else if (column.prop == 'd.belong') {
        if (column.order == 'ascending') {
          this.page.orderColume = 'd.belong'
          this.page.orderRule = 'ASC'
        } else {
          this.page.orderColume = 'd.belong'
          this.page.orderRule = 'DESC'
        }
      } else if (column.prop == 'd.status') {
        if (column.order == 'ascending') {
          this.page.orderColume = 'd.status'
          this.page.orderRule = 'ASC'
        } else {
          this.page.orderColume = 'd.status'
          this.page.orderRule = 'DESC'
        }
      } else if (column.prop == 'd.isViolation') {
        if (column.order == 'ascending') {
          this.page.orderColume = 'd.isViolation'
          this.page.orderRule = 'ASC'
        } else {
          this.page.orderColume = 'd.isViolation'
          this.page.orderRule = 'DESC'
        }
      } else if (column.prop == 'd.signStatus') {
        if (column.order == 'ascending') {
          this.page.orderColume = 'd.signStatus'
          this.page.orderRule = 'ASC'
        } else {
          this.page.orderColume = 'd.signStatus'
          this.page.orderRule = 'DESC'
        }
      } else if (column.prop == 'devcon.conStatus') {
        if (column.order == 'ascending') {
          this.page.orderColume = 'devcon.conStatus'
          this.page.orderRule = 'ASC'
        } else {
          this.page.orderColume = 'devcon.conStatus'
          this.page.orderRule = 'DESC'
        }
      } else if (column.prop == 'd.healthDegree') {
        if (column.order == 'ascending') {
          this.page.orderColume = 'd.healthDegree'
          this.page.orderRule = 'ASC'
        } else {
          this.page.orderColume = 'd.healthDegree'
          this.page.orderRule = 'DESC'
        }
      } else if (column.prop == 'devcon.lastLoginTime') {
        if (column.order == 'ascending') {
          this.page.orderColume = 'devcon.lastLoginTime'
          this.page.orderRule = 'ASC'
        } else {
          this.page.orderColume = 'devcon.lastLoginTime'
          this.page.orderRule = 'DESC'
        }
      }
      this.tableList()
    },
    // 关闭
    handleClose() {
      this.visibaelFlag = false //提示消息  关闭
      this.dialogVisible = false //详情弹框  关闭
      this.install = false //安卓详情弹框  关闭
      this.installs = false //ios详情弹框  关闭
      this.input = ''
      this.input1 = ''
    },
    heightCustom() {
      this.height.height = this.num
    },
    //确定回调事件
    determine() {
      this.visibaelFlag = false
    },
    async getlist(user) {
      var param = {
        deviceUDID: user.deviceUDID
      }
      console.log(param)
      const res = await this.$axios.post(
        '/httpServe/device/command/get',
        param,
        true
      )
      // const res = await this.$axios.post("/httpServe/device/uninstallCode/get", param, true);
      console.log(res)
      // this.form = res.data.uninstallCode;
      // console.log(this.form);
    },
    // 锁定设备 lockDevice
    async lockDevice() {
      var param = {
        type: 'DeviceLock', //标记状态
        deviceUDID: this.user.deviceUDID
      }
      console.log(param)
      this.$confirm(this.$t('DeviceManage.Device.Multipledelete.Lockdetermination'),
        this.$t('public.PromptMessage'), {
          confirmButtonText: this.$t('public.Verify'),
        cancelButtonText: this.$t('public.Cancel'),
        customClass: 'persdsd',
        type: 'warning'
      })
        .then(async () => {
          const res = await this.$axios.post(
            '/httpServe/device/command/exec',
            param,
            true
          )
          // const res = await this.$axios.post("api/device/command/exec.do", param, true);
          console.log(res)
          // this.$emit("sun", "锁定设备命令下发成功");
          // this.visibaelFlag = true;
          this.$message({
            message: this.$t('DeviceManage.Device.Multipledelete.Locksuccess'),
            type: 'success',
            offset: 100
          })
        })
        .catch(() => {
          return false
        })
    },
    // 解锁设备
    async unLockDevice(){
      var param = {
        type: 'DeviceUnLock', //标记状态
        deviceUDID: this.user.deviceUDID
      }
      console.log(param)
      this.$confirm(this.$t('DeviceManage.Device.Trajectory.LockUnlock'),
        this.$t('public.PromptMessage'), {
          confirmButtonText: this.$t('public.Verify'),
        cancelButtonText: this.$t('public.Cancel'),
        customClass: 'persdsd',
        type: 'warning'
      })
        .then(async () => {
          const res = await this.$axios.post(
            '/httpServe/device/command/exec',
            param,
            true
          )
          // const res = await this.$axios.post("api/device/command/exec.do", param, true);
          console.log(res)
          // this.$emit("sun", "锁定设备命令下发成功");
          // this.visibaelFlag = true;
          this.$message({
            message: this.$t('DeviceManage.Device.Multipledelete.Unlockingsucceeded'),
            type: 'success',
            offset: 100
          })
        })
        .catch(() => {
          return false
        })
    },
    // 锁定密码 password
    async password() {
      var param = {
        type: 'DeviceLock', //标记状态
        deviceUDID: this.user.deviceUDID,
        pwd: this.input2
      }
      console.log(param)
      this.$confirm(this.$t('DeviceManage.Device.Trajectory.Passwordconfirmation'),
        this.$t('public.PromptMessage'), {
          confirmButtonText: this.$t('public.Verify'),
        cancelButtonText: this.$t('public.Cancel'),
        customClass: 'persdsd',
        type: 'warning'
      })
        .then(async () => {
          const res = await this.$axios.post(
            '/httpServe/device/command/exec',
            param,
            true
          )
          // const res = await this.$axios.post("/api/device/command/exec.do", param, true);
          console.log(res)
          // this.$emit("sun", "密码锁定命令下发成功");
          // this.visibaelFlag = true;
          this.$message({
            message: this.$t('DeviceManage.Device.Trajectory.Passwordsuccessful'),
            type: 'success',
            offset: 100
          })
        })
        .catch(() => {
          return false
        })
    },
    // 清除密码 clearpassword
    async clearpassword() {
      var param = {
        type: 'ClearPasscode', //标记状态
        deviceUDID: this.user.deviceUDID,
        pwd: ''
      }
      console.log(param)
      this.$confirm(this.$t('DeviceManage.Device.Trajectory.Clearthepassword'),
        this.$t('public.PromptMessage'), {
          confirmButtonText: this.$t('public.Verify'),
        cancelButtonText: this.$t('public.Cancel'),
        customClass: 'persdsd',
        type: 'warning'
      })
        .then(async () => {
          const res = await this.$axios.post(
            '/httpServe/device/command/exec',
            param,
            true
          )
          // const res = await this.$axios.post("/api/device/command/exec.do", param, true);
          console.log(res)
          // this.$emit("sun", "清除密码命令下发成功");
          // this.visibaelFlag = true;
          this.$message({
            message: this.$t('DeviceManage.Device.Trajectory.Successfullyissued'),
            type: 'success',
            offset: 100
          })
        })
        .catch(() => {
          return false
        })
    },
    // 清除数据 lockDevice
    async Cleardata() {
      var param = {
        type: 'removeappdata', //标记状态
        deviceUDID: this.user.deviceUDID
      }
      console.log(param)
      this.$confirm(this.$t('DeviceManage.Device.Multipledelete.ClearOK'),
        this.$t('public.PromptMessage'), {
          confirmButtonText: this.$t('public.Verify'),
        cancelButtonText: this.$t('public.Cancel'),
          customClass: 'persdsd',
          type: 'warning'
        }
      )
        .then(async () => {
          const res = await this.$axios.post(
            '/httpServe/device/command/exec',
            param,
            true
          )
          // const res = await this.$axios.post("api/device/command/exec.do", param, true);
          console.log(res)
          // this.$emit("sun", "清除数据命令下发成功");
          // this.visibaelFlag = true;
          this.$message({
            message: this.$t('DeviceManage.Device.Multipledelete.Successfullycleared'),
            type: 'success',
            offset: 100
          })
        })
        .catch(() => {
          return false
        })
    },
    // 强制注销 lockDevice
    async Forcelogoff() {
      var param = {
        type: 'logOut', //标记状态
        deviceUDID: this.user.deviceUDID
      }
      console.log(param)
      this.$confirm(this.$t('DeviceManage.Device.Multipledelete.LogoffOk'),
        this.$t('public.PromptMessage'), {
          confirmButtonText: this.$t('public.Verify'),
        cancelButtonText: this.$t('public.Cancel'),
        customClass: 'persdsd',
        type: 'warning'
      })
        .then(async () => {
          const res = await this.$axios.post(
            '/httpServe/device/command/exec',
            param,
            true
          )
          // const res = await this.$axios.post("api/device/command/exec.do", param, true);
          console.log(res)
          // this.$emit("sun", "强制注销命令下发成功");
          // this.visibaelFlag = true;
          this.$message({
            message: this.$t('DeviceManage.Device.Multipledelete.Loggedsuccessfully'),
            type: 'success',
            offset: 100
          })
        })
        .catch(() => {
          return false
        })
    },
    // 卸载码 lockDevice
    async uninstall() {
      var param = {
        deviceUDID: this.user.deviceUDID
      }
      console.log(param)
      const res = await this.$axios.post(
        '/httpServe/device/command/create',
        // "/api/device/uninstallCode/create.do",
        param,
        true
      )
      this.form.uninstallCode = res.data.uninstallCode
      console.log(res)
      this.visibaelFlag = true
      this.uninstallCode = true
      this.disabled = true
      this.type = 'info'
    },
    // 恢复出厂 recovery
    async recovery() {
      var param = {
        type: 'EraseDevice', //标记状态
        deviceUDID: this.user.deviceUDID
      }
      console.log(param)
      this.$confirm(this.$t('DeviceManage.Device.Multipledelete.ExfactoryOK'),
        this.$t('public.PromptMessage'), {
          confirmButtonText: this.$t('public.Verify'),
        cancelButtonText: this.$t('public.Cancel'),
          customClass: 'persdsd',
          type: 'warning'
        }
      )
        .then(async () => {
          const res = await this.$axios.post(
            '/httpServe/device/command/exec',
            param,
            true
          )
          // const res = await this.$axios.post("api/device/command/exec.do", param, true);
          console.log(res)
          // this.$emit("sun", "恢复出厂命令下发成功");
          // this.visibaelFlag = true;
          this.$message({
            message: this.$t('DeviceManage.Device.Multipledelete.FactorySuccess'),
            type: 'success',
            offset: 100
          })
        })

        .catch(() => {
          return false
        })
    },
    // 提取日志 extract
    async extract() {
      var param = {
        type: 'extractLog', //标记状态
        deviceUDID: this.user.deviceUDID
      }
      console.log(param)
      this.$confirm(this.$t('DeviceManage.Device.Multipledelete.LogOK'),
        this.$t('public.PromptMessage'), {
          confirmButtonText: this.$t('public.Verify'),
        cancelButtonText: this.$t('public.Cancel'), 
        customClass: 'persdsd',
        type: 'warning'
      })
        .then(async () => {
          const res = await this.$axios.post(
            '/httpServe/device/command/exec',
            param,
            true
          )
          // const res = await this.$axios.post("api/device/command/exec.do", param, true);
          console.log(res)
          // this.$emit("sun", "提取日志命令下发成功");
          // this.visibaelFlag = true;
          this.$message({
            message: this.$t('DeviceManage.Device.Multipledelete.Logsuccessful'),
            type: 'success',
            offset: 100
          })
        })
        .catch(() => {
          return false
        })
    },
    // 安装应用 Install
    async Install() {
      let user = JSON.parse(window.sessionStorage.getItem('user'))
      this.dialogVisible = true
      var param = {
        // osType: user.osId == 20 ? "Android" : "iOS",
        deviceUDID: user.deviceUDID,
        // type: "InstallApplication", //安装
        // status: user.status,
        // deviceId: user.id,
        osTypeId: user.osId,
        // udid: user.deid,
        appName: this.input, //input搜索
        currentPage: 1,
        pageSize: 100000,
        orderColume: this.Instpage.orderColume,
        orderRule: this.Instpage.orderRule,
        appStatus: '2',
        isGetH5App: 0,// 是否需要H5应用
        isGetSafe:1,
      }
      console.log(param)

      const res = await this.$axios.post(
        '/httpServe/entApp/getDataInfo',
        param,
        true
      )
      console.log(res)
      this.gridData = res.data.content
      // conosle.log(this.gridData);
      this.isnlist = res.data.content
      this.dataTolCount = res.data.total
      this.$forceUpdate()
      // this.$emit("sun", "提取日志命令下发成功");
      // this.visibaelFlag = true;
    },
    // 查询
    async searchDev() {
      if (this.input != '') {
        this.gridData = this.gridData.filter((item) => {
          return item.appName.includes(this.input)
        })
      } else {
        // this.Install();
        this.gridData = this.isnlist
      }
    },
    // 安装
    async joinType() {
      var entAppId = []
      this.datal.forEach((i) => {
        entAppId.push(String(i.id))
      })
      if (this.datal.length == 0) {
        // this.$emit("sun", "请至少选中一项");
        this.$message({
          message: this.$t('DeviceManage.Device.Installationapp.Selectanitem'),
          type: 'error',
          offset: 100
        })
      } else if (this.datal.length >= 1) {
        this.$confirm(this.$t('DeviceManage.Device.Installationapp.Installationcommand'),
        this.$t('public.PromptMessage'), {
          confirmButtonText: this.$t('public.Verify'),
          cancelButtonText: this.$t('public.Cancel'),
          customClass: 'persdsd',
          type: 'warning'
        })
          .then(async () => {
            var param = {
              type: 'InstallApplication', //标记状态
              deviceUDID: this.user.deviceUDID,
              entAppId: entAppId //应用id
            }
            console.log(param)
            const res = await this.$axios.post(
              '/httpServe/device/command/exec',
              param,
              true
            )
            console.log(res)
            // this.$emit("sun", "下发安装应用命令成功");
            // this.visibaelFlag = true;
            this.$message({
              message: this.$t('DeviceManage.Device.Installationapp.Successfulinstallation'),
              type: 'success',
              offset: 100
            })
          })
          .catch(() => {
            return false
          })
      }
    },

    // 卸载应用 dump
    // ios  10
    async dumps() {
      let user = JSON.parse(window.sessionStorage.getItem('user'))
      this.installs = true
      var param = {
        // deviceId: user.id,
        deviceUDIDs: [user.deviceUDID],
        osType: user.osId,
        // status: user.status,
        // type: "RemoveApplication", //卸载
        // isSystem: 1,
        // osTypeId: user.osId,
        // udid: user.deid,
        // where: {
        //   name: "",
        // },
        orderColume: 'createTime',
        orderRule: 'DESC',
        currentPage: 1
        // "appName":this.input1
      }
      console.log(param)
      const res = await this.$axios.post(
        '/httpServe/entApp/selectApp',
        param,
        true
      )
      console.log(res)
      this.gridData = res.data.content
      console.log(this.gridData)
      this.isnlist = res.data.total
      this.$forceUpdate()
      // this.$emit("sun", "提取日志命令下发成功");
      // this.visibaelFlag = true;
    },
    searchDevss() {
      console.log(11111111,"--------------");
      if (this.input1 != "") {
        this.gridData = this.gridData.filter((item) => {
          return item.name.includes(this.input1);
        });
      } else {
        // this.dump();
        this.dumps();
        console.log(3232,"9999999");
        this.gridData = this.isnlist;
      }
    },
    // 安卓   20
    async dump() {
      let user = JSON.parse(window.sessionStorage.getItem('user'))
      this.install = true
      var param = {
        // deviceId: user.id,
        deviceUDIDs: [user.deviceUDID],
        osType: user.osId,
        // status: user.status,
        // type: "RemoveApplication", //卸载
        // isSystem: 1,
        // osTypeId: user.osId,
        // udid: user.deid,
        // where: {
        //   name: "",
        // },
        orderColume: 'lastupdatetime',
        orderRule: 'DESC',
        currentPage: 1
        // "appName":this.input1
      }
      console.log(param)
      const res = await this.$axios.post(
        '/httpServe/entApp/selectApp',
        param,
        true
      )
      console.log(res)
      this.gridData = res.data.content
      console.log(this.gridData)
      this.isnlist = res.data.total
      this.$forceUpdate()
      // this.$emit("sun", "提取日志命令下发成功");
      // this.visibaelFlag = true;
    },
    // 卸载
    async xiezai() {
      var removeId = []
      this.datal.forEach((i) => {
        removeId.push(String(i.id))
      })
      if (this.datal.length == 0) {
        // this.$emit("sun", "请至少选中一项");
        this.$message({
          message: this.$t('DeviceManage.Device.Installationapp.Selectanitem'),
          type: 'error',
          offset: 100
        })
      } else if (this.datal.length >= 1) {
        this.$confirm(this.$t('DeviceManage.Device.Uninstallapp.Unloadcommand'),
        this.$t('public.PromptMessage'), {
          confirmButtonText: this.$t('public.Verify'),
          cancelButtonText: this.$t('public.Cancel'),
          customClass: 'persdsd',
          type: 'warning'
        })
          .then(async () => {
            var param = {
              type: 'RemoveApplication', //标记状态
              deviceUDID: this.user.deviceUDID,
              removeId: removeId
            }
            console.log(param)
            const res = await this.$axios.post(
              '/httpServe/device/command/exec',
              param,
              true
            )
            console.log(res)
            // this.$emit("sun", "下发卸载应用命令成功");
            // this.visibaelFlag = true;
            this.$message({
              message: this.$t('DeviceManage.Device.Uninstallapp.Uninstallsuccessfully'),
              type: 'success',
              offset: 100
            })
          })
          .catch(() => {
            return false
          })
      }
    },
    async searchDevs() {
      console.log(11111111,"--------------");
      if (this.input1 != "") {
        this.gridData = this.gridData.filter((item) => {
          return item.appName.includes(this.input1)
        })
      } else {
        this.dump();
        // this.dumps();
        console.log(3232,"9999999");
        this.gridData = this.isnlist;
      }
    },
    handleSelectionChange1(val) {
      this.datal = val
    },
    // 设备丢失 iOS
    async deviceLost(){
      console.log(this.user,"this.user123123");
      var param = {
        signStatus: '3', //标记状态
        ids: [this.user.id],
      };
      console.log(param);
      this.$confirm(this.$t('DeviceManage.Device.Trajectory.Setlossmode'),
        this.$t('public.PromptMessage'), {
          confirmButtonText: this.$t('public.Verify'),
          cancelButtonText: this.$t('public.Cancel'),
        customClass: "persdsd",
        type: "warning",
      })
        .then(async () => {
          const res = await this.$axios.post("/httpServe/device/updateSignStatus", param, true);
          console.log(res);
          this.$message({
            message: this.$t('DeviceManage.Device.Trajectory.Issuedcommand'),
            type: "success",
            offset: 100,
          });
        })
        .catch(() => {
          return false;
        });
    },
    // 解除丢失 iOS
    async disableLost(){
      var param = {
        type: "DisableLostMode", //标记状态
        deviceUDID: this.user.deviceUDID,
      };
      console.log(param);
      this.$confirm(this.$t('DeviceManage.Device.Trajectory.Releasetheloss'),
        this.$t('public.PromptMessage'), {
          confirmButtonText: this.$t('public.Verify'),
          cancelButtonText: this.$t('public.Cancel'),
        customClass: "persdsd",
        type: "warning",
      })
        .then(async () => {
          const res = await this.$axios.post("/httpServe/device/command/exec", param, true);
          console.log(res);
          this.$message({
            message: this.$t('DeviceManage.Device.Trajectory.Toremovetheloss'),
            type: "success",
            offset: 100,
          });
        })
        .catch(() => {
          return false;
        });
    },
    // 设备关机 shutdown
    async shutdown() {
      var param = {
        type: 'shutDown', //标记状态
        deviceUDID: this.user.deviceUDID
      }
      console.log(param)
      this.$confirm(this.$t('DeviceManage.Device.Trajectory.Deviceshutdowns'),
        this.$t('public.PromptMessage'), {
          confirmButtonText: this.$t('public.Verify'),
          cancelButtonText: this.$t('public.Cancel'),
        customClass: 'persdsd',
        type: 'warning'
      })
        .then(async () => {
          const res = await this.$axios.post(
            '/httpServe/device/command/exec',
            param,
            true
          )
          // const res = await this.$axios.post("api/device/command/exec.do", param, true);
          console.log(res)
          // this.$emit("sun", "设备关机命令下发成功");
          // this.visibaelFlag = true;
          this.$message({
            message: this.$t('DeviceManage.Device.Trajectory.Thedeviceshutdown'),
            type: 'success',
            offset: 100
          })
        })
        .catch(() => {
          return false
        })
    },
    // 设备重启 restart
    async restart() {
      var param = {
        type: 'restartDevice', //标记状态
        deviceUDID: this.user.deviceUDID
      }
      console.log(param)
      this.$confirm(this.$t('DeviceManage.Device.Trajectory.Restartthedevice'),
        this.$t('public.PromptMessage'), {
          confirmButtonText: this.$t('public.Verify'),
          cancelButtonText: this.$t('public.Cancel'),
        customClass: 'persdsd',
        type: 'warning'
      })
        .then(async () => {
          const res = await this.$axios.post(
            '/httpServe/device/command/exec',
            param,
            true
          )
          // const res = await this.$axios.post("api/device/command/exec.do", param, true);
          console.log(res)
          // this.$emit("sun", "设备重启命令下发成功");
          // this.visibaelFlag = true;
          this.$message({
            message: this.$t('DeviceManage.Device.Trajectory.Restartcommand'),
            type: 'success',
            offset: 100
          })
        })
        .catch(() => {
          return false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 1px !important;
}
.el-form-item__label {
  color: #000000 !important;
}
.wrap .group .msg_l0 {
  color: #807e7e !important;
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
</style>