<template>
  <div>
    <!-- 查询框 -->
    <div style="height: 42px">
      <select-input :options="options1" @selectSearchChange="selectSearchChange" @searchList="onSearchTable"></select-input>

      <!-- <el-select style="width: 100px; margin-right: 5px" size="mini" :placeholder="$t('public.Username')" v-model="page.loginName" @change="searchTypeChange">
        <el-option v-for="item in options1" :key="item.value1" :label="$t(`DeviceManage.Device.Addauser.${item.label1}`)" :value="item.label1">
        </el-option>
      </el-select>
      <el-input v-model.trim="input" size="mini" type="text" :placeholder="`请输入${placeholder}`" style="width: 160px" @click="onSearchTable" @keyup.enter.native="onSearchTable">
        <i @click="onSearchTable" slot="suffix" class="el-input__icon el-icon-search" style="cursor: pointer"></i>
      </el-input> -->
    </div>
    <div>
      <el-row :gutter="16" class="mt15" style="margin-left: 0px; margin-right: 2px; overflow: auto">
        <el-table stripe ref="multipleTable" fit size="mini" class="sort_table" tooltip-effect="dark" :data="tableList" :header-cell-style="{ background: '#f7f8fa', color: '#606266' }" @row-click="rowClick" @current-change="clickID">
          <el-table-column width="40">
            <template slot-scope="scope">
              <el-radio class="device_radio" :label="scope.row.loginName" v-model="radioId">&nbsp;</el-radio>
            </template>
          </el-table-column>
          <el-table-column prop="loginName" :label="$t('public.Username')" width="80" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="userName" :label="$t('public.Name')" width="80" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="groupFullName" :label="$t('public.Section')" :show-overflow-tooltip="true">
            <template v-slot="{ row }">
              <span v-if="row.groupFullName">
                {{ row.groupFullName.substr(groupFullNameShowByIndex) }}
              </span>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </div>

    <div style="margin-top: 10px">
      {{$t('DeviceManage.Device.Installationapp.Common')}}
      <span style="color: '#000'">{{ tableList.length }}</span>
      {{$t('DeviceManage.Device.Installationapp.Records')}}
    </div>
    <!-- <pagination
      style="margin-right: 10px"
      :page="page.currentPage"
      :limit="page.pageSize"
      :total="page.total"
      :pageSizes="[10, 20, 30, 40]"
      v-on:handleChildGetList="handleParentGetList"
    ></pagination> -->
    <!-- 全选弹出框 -->
    <!-- <ul class="tableCheckBox" v-show="isCheckShow" @mouseleave="leave">
      <li @click="curSelection()">当前页全选</li>
     <li @click="toggleAllSelection()">所有页全选</li>
      <li @click="toggleSelection()">取消全选</li>
    </ul> -->
  </div>
</template>

<script>
import pagination from '@/components/pagination/page.vue'
import selectInput from "@/components/selectInput"
export default {
  components: { pagination,selectInput },

  data() {
    return {
      selectVal:{
        deviceName:'',
        InputText:""
      },
      seleShift: '', // 选中下拉框转译
      inputText: '',
      groupFullNameShowByIndex: '', //用户部门截取的下标
      input: '', //表格中查询用户名
      isCheckShow: false, // 全选弹框
      checked: false, // 全选框双向绑定
      tableList: [], //表格数据
      riglist: [], //表格数据
      page: {
        currentPage: 1,
        pageSize: 100000,
        orderColume: '',
        orderRule: '',
        total: 0,
        loginName: '用户名',
        userName: '',
        phone: ''
      },
      radioId: '',
      id: '',
      options1: [
        {
          value: 'loginName',
          label: '用户名'
        },
        {
          value: 'userName',
          label: '姓名'
        }
      ],
      placeholder: '用户名'
    }
  },
  created() {},
  mounted() {
    console.log(this.selectVal.deviceName,'===========');
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      'groupFullNameShowByIndex'
    )
  },
  methods: {
    selectSearchChange(item){
      this.options1.forEach((k) => {
        if (item == k.label) {
          this.seleShift = k.value
        }
      })
      this.placeholder = item
    },
    //全选遮罩层
    maskLayer() {
      this.isCheckShow = true
    },
    // 鼠标移出弹框
    leave() {
      this.isCheckShow = false
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.page.currentPage = page
      this.page.pageSize = limit
      // // 调用查询方法
      this.onSearchTable(this.selectVal)
    },
    sing() {
      // alert("123")
      this.input = ''
    },
    //下拉选中查询类型--转译input中placeholder显示
    // searchTypeChange(item) {
    //   this.page.loginName = item
    //   this.placeholder = item
    //   this.options1.forEach((k) => {
    //     if (item == k.label) {
    //       this.seleShift = k.value
    //     }
    //   })
    // },
    //获取表格数据
    async getList(v) {
      console.log(v, '9090909090908080780')
      var params = {
        currentPage: this.page.currentPage,
        pageSize: this.page.pageSize,
        orderColume: 'u.updateTime',
        orderRule: 'DESC',
        groupId: v[0],
        statu: '',
        loginName: this.page.loginName == '用户名' ? this.input : '',
        userName: this.page.loginName == '姓名' ? this.input : '',
        phone: ''
      }
      const res = await this.$axios.post(
        '/httpServe/userInfo/getDeliveryUser',
        params,
        true
      )
      console.log(res.data, '表格数据')
      this.tableList = res.data.content
      this.riglist = res.data
      this.page.total = res.data.length
    },
    //表格查询部门
    async onSearchTable(val) {
      var params = {
        currentPage: this.page.currentPage,
        pageSize: this.page.pageSize,
        orderColume: 'u.updateTime',
        orderRule: 'DESC',
        groupId: '',
        statu: '',
        loginName: this.page.loginName == '用户名' ? val.InputText : '',
        userName: this.page.loginName == '姓名' ? val.InputText : '',
        phone: ''
      }
      console.log(params, 'can')
      const res = await this.$axios.post(
        '/httpServe/userInfo/getDeliveryUser',
        params,
        true
      )
      console.log(res, '====')
      console.log(res.data, '表格数据')
      this.tableList = res.data.content
      this.riglist = res.data
      this.page.total = res.data.length
    },

    rowClick(row) {
      console.log(row)
      window.sessionStorage.setItem('irem', JSON.stringify(row))
      // this.radioId = row.loginName;
      //  console.log(arr)
    },
    clickID(obj) {
      console.log(obj, '================')
      window.sessionStorage.setItem('ws', obj.id)
    }
  }
}
</script>
<style lang="scss" scoped>
//全选弹框
// .tableCheckBox {
//   border: 1px solid #1e89e5;
//   border-radius: 3px;
//   width: 80px;
//   background-color: #fff;
//   position: absolute;
//   top: 95px;
//   left: 2%;
//   z-index: 999;
//   // background:rgba(255, 255, 255,.1)
//   li {
//     margin-top: -1px;
//     height: 30px;
//     line-height: 30px;
//     text-align: center;
//     color: #575656;
//     cursor: pointer;
//     font-weight: 400;
//     font-family: Microsoft YaHei;
//     font-size: 12px;
//     list-style: none !important;
//     div {
//       display: inline-block;
//     }
//   }
//   li:hover {
//     color: #d38a08;
//     text-decoration: underline;
//   }
// }
// 暂无数据
::v-deep .el-table__empty-block {
  width: 20% !important;
}
::v-deep .device_radio {
  padding-left: 3px;
}
</style>
