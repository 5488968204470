<template>
  <div style="display: flex; height: 700px">
    <div style="margin-top: 0px">
      <el-form ref="form" :model="form" label-width="190px">
        <!-- 设备名称 -->
        <el-form-item :label="$t('public.DeviceName') + '：'">
          {{ form.deviceName }}</el-form-item
        >

        <!-- 健康度： -->
        <el-form-item :label="$t('DeviceManage.Device.Healthdegree') + '：'">
          <el-form-item v-if="form.healthDegree">
            {{ form.healthDegree }}
          </el-form-item>
          <el-form-item v-else>
            {{ "N/A" }}
          </el-form-item>
        </el-form-item>

        <!-- 合规状态 -->
        <el-form-item
          :label="$t('DeviceManage.Device.Compliancestatus') + '：'"
        >
          <el-form-item v-if="form.isViolation == 0">
            {{ $t("DeviceManage.Device.Getoutofline") }}
          </el-form-item>
          <el-form-item v-else-if="form.isViolation == 1">
            {{ $t("public.Normal") }}
          </el-form-item>
          <el-form-item v-else>
            {{ $t("DeviceManage.Device.Unknown") }}
          </el-form-item>
        </el-form-item>

        <!-- 设备越狱 -->
        <!-- 20---Android -->
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.Devicejailbreak') + '：'"
          v-if="user.osId == 20"
        >
          <el-form-item v-if="deviceInfo_bank.root == '1'">
            {{ $t("DeviceManage.Device.BasicNews.PrisonBreak") }}
          </el-form-item>
          <el-form-item v-else-if="deviceInfo_bank.root == '0'">
            {{ $t("public.Normal") }}
          </el-form-item>
          <el-form-item v-else>
            {{ $t("DeviceManage.Device.Unknown") }}
          </el-form-item>
        </el-form-item>
        <!-- 10---iOS -->
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.Devicejailbreak') + '：'"
          v-if="user.osId == 10"
        >
          <el-form-item v-if="form.iosHardwareInfo.root == '1'">
            {{ $t("DeviceManage.Device.BasicNews.PrisonBreak") }}
          </el-form-item>
          <el-form-item v-else-if="form.iosHardwareInfo.root == '0'">
            {{ $t("public.Normal") }}
          </el-form-item>
          <el-form-item v-else>
            {{ $t("DeviceManage.Device.Unknown") }}
          </el-form-item>
          <!-- {{ form.isJailBreak == 1 ? "{{$t('public.Normal')}}" : "已越狱（Root）" }} -->
        </el-form-item>

        <!-- 设备状态 -->
        <el-form-item :label="$t('DeviceManage.Device.EquipmentStatus') + '：'">
          <el-form-item v-if="form.status == 5">
            {{ $t("DeviceManage.Device.BasicNews.Notactive") }}
          </el-form-item>
          <el-form-item v-else-if="form.status == 10">
            {{ $t("DeviceManage.Device.Pendingregistration") }}
          </el-form-item>
          <el-form-item v-else-if="form.status == 15">
            {{ $t("DeviceManage.Device.BasicNews.Active") }}
          </el-form-item>
          <el-form-item v-else-if="form.status == 25">
            {{ $t("DeviceManage.Device.SignOUT") }}
          </el-form-item>
          <el-form-item v-else-if="form.status == 35">
            {{ $t("public.Outofservice") }}
          </el-form-item>
          <el-form-item v-else> {{ "N/A" }} </el-form-item>
        </el-form-item>

        <!-- 绑定状态 -->
        <!-- <el-form-item label="绑定状态：">
          <el-form-item v-if="form.bindStatus == 1"> 绑定 </el-form-item>
          <el-form-item v-if="form.bindStatus == 0"> 解绑 </el-form-item> 
        </el-form-item> -->

        <!-- 在线状态 -->
        <el-form-item :label="$t('DeviceManage.Device.Onlinesta') + '：'">
          <el-form-item v-if="form.deviceConIMStatus.conStatus == 0">
            {{ $t("DeviceManage.Device.Offline") }}
          </el-form-item>
          <el-form-item v-else-if="form.deviceConIMStatus.conStatus == 1">
            {{ $t("DeviceManage.Device.Online") }}
          </el-form-item>
          <el-form-item v-else>
            {{ $t("DeviceManage.Device.Unknown") }}
          </el-form-item>
        </el-form-item>

        <!-- 隧道状态 -->
        <el-form-item :label="$t('DeviceManage.Device.Tunnelstatus') + '：'">
          <!-- Forbidden 0禁用 1启用-->
          <el-form-item v-if="form.tunnelStatus == 0">
            {{ $t("public.Forbidden") }}
          </el-form-item>
          <el-form-item v-else-if="form.tunnelStatus == 1">
            {{ $t("public.Enable") }}
          </el-form-item>
          <!-- 未知 -->
          <el-form-item v-else>
            {{ $t("DeviceManage.Device.Unknown") }}
          </el-form-item>
        </el-form-item>

        <!-- 蓝牙 -->
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.Bluetooth') + '：'"
          v-if="user.osId == 20"
        >
          <el-form-item v-if="form.androidHardwareInfo.bluetooth == '0'">
            {{ $t("DeviceManage.Device.BasicNews.Close") }}
          </el-form-item>
          <el-form-item v-else-if="form.androidHardwareInfo.bluetooth == '1'">
            {{ $t("DeviceManage.Device.BasicNews.Open") }}
          </el-form-item>
          <el-form-item v-else>
            {{ $t("DeviceManage.Device.Unknown") }}
          </el-form-item>
        </el-form-item>
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.Bluetooth') + '：'"
          v-if="user.osId == 10"
        >
          <el-form-item v-if="form.iosHardwareInfo.bluetooth == '0'">
            {{ $t("DeviceManage.Device.BasicNews.Close") }}
          </el-form-item>
          <el-form-item v-else-if="form.iosHardwareInfo.bluetooth == '1'">
            {{ $t("DeviceManage.Device.BasicNews.Open") }}
          </el-form-item>
          <el-form-item v-else>
            {{ $t("DeviceManage.Device.Unknown") }}
          </el-form-item>
        </el-form-item>

        <!-- WLAN网络 -->
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.WLANnetwork') + '：'"
          v-if="user.osId == 20"
        >
          <el-form-item v-if="form.androidHardwareInfo.wifi == '0'">
            {{ $t("DeviceManage.Device.BasicNews.Close") }}
          </el-form-item>
          <el-form-item v-else-if="form.androidHardwareInfo.wifi == '1'">
            {{ $t("DeviceManage.Device.BasicNews.Open") }}
          </el-form-item>
          <el-form-item v-else>
            {{ $t("DeviceManage.Device.Unknown") }}
          </el-form-item>
        </el-form-item>
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.WLANnetwork') + '：'"
          v-if="user.osId == 10"
        >
          <el-form-item v-if="form.iosHardwareInfo.wifi == '0'">
            {{ $t("DeviceManage.Device.BasicNews.Close") }}
          </el-form-item>
          <el-form-item v-else-if="form.iosHardwareInfo.wifi == '1'">
            {{ $t("DeviceManage.Device.BasicNews.Open") }}
          </el-form-item>
          <el-form-item v-else>
            {{ $t("DeviceManage.Device.Unknown") }}
          </el-form-item>
        </el-form-item>

        <!-- 移动网络 -->
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.Mobilenetwork') + '：'"
          v-if="user.osId == 20"
        >
          <el-form-item v-if="form.androidHardwareInfo.datalink == '0'">
            {{ $t("DeviceManage.Device.BasicNews.Close") }}
          </el-form-item>
          <el-form-item v-else-if="form.androidHardwareInfo.datalink == '1'">
            {{ $t("DeviceManage.Device.BasicNews.Open") }}
          </el-form-item>
          <el-form-item v-else>
            {{ $t("DeviceManage.Device.Unknown") }}
          </el-form-item>
        </el-form-item>
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.Mobilenetwork') + '：'"
          v-if="user.osId == 10"
        >
          <el-form-item v-if="form.iosHardwareInfo.datalink == '0'">
            {{ $t("DeviceManage.Device.BasicNews.Close") }}
          </el-form-item>
          <el-form-item v-else-if="form.iosHardwareInfo.datalink == '1'">
            {{ $t("DeviceManage.Device.BasicNews.Open") }}
          </el-form-item>
          <el-form-item v-else>
            {{ $t("DeviceManage.Device.Unknown") }}
          </el-form-item>
        </el-form-item>

        <!-- 个人热点 -->
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.PersonalHotspot') + '：'"
          v-if="user.osId == 20"
        >
          <el-form-item v-if="form.androidHardwareInfo.hotspot == '0'">
            {{ $t("DeviceManage.Device.BasicNews.Close") }}
          </el-form-item>
          <el-form-item v-else-if="form.androidHardwareInfo.hotspot == '1'">
            {{ $t("DeviceManage.Device.BasicNews.Open") }}
          </el-form-item>
          <el-form-item v-else>
            {{ $t("DeviceManage.Device.Unknown") }}
          </el-form-item>
        </el-form-item>
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.PersonalHotspot') + '：'"
          v-if="user.osId == 10"
        >
          <el-form-item v-if="form.iosHardwareInfo.hotspot == '0'">
            {{ $t("DeviceManage.Device.BasicNews.Close") }}
          </el-form-item>
          <el-form-item v-else-if="form.iosHardwareInfo.hotspot == '1'">
            {{ $t("DeviceManage.Device.BasicNews.Open") }}
          </el-form-item>
          <el-form-item v-else>
            {{ $t("DeviceManage.Device.Unknown") }}
          </el-form-item>
        </el-form-item>

        <!-- 系统定位 -->
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.Systempositioning') + '：'"
          v-if="user.osId == 20"
        >
          <el-form-item v-if="form.androidHardwareInfo.location == '0'">
            {{ $t("DeviceManage.Device.BasicNews.Close") }}
          </el-form-item>
          <el-form-item v-else-if="form.androidHardwareInfo.location == '1'">
            {{ $t("DeviceManage.Device.BasicNews.Open") }}
          </el-form-item>
          <el-form-item v-else> {{ "N/A" }} </el-form-item>
        </el-form-item>
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.Systempositioning') + '：'"
          v-if="user.osId == 10"
        >
          <el-form-item v-if="form.iosHardwareInfo.location == '0'">
            {{ $t("DeviceManage.Device.BasicNews.Close") }}
          </el-form-item>
          <el-form-item v-else-if="form.iosHardwareInfo.location == '1'">
            {{ $t("DeviceManage.Device.BasicNews.Open") }}
          </el-form-item>
          <el-form-item v-else> {{ "N/A" }} </el-form-item>
        </el-form-item>

        <!-- USB调试 -->
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.USBdebugging') + '：'"
          v-if="user.osId == 20"
        >
          <el-form-item v-if="form.androidHardwareInfo.usb == '0'">
            {{ $t("DeviceManage.Device.BasicNews.Close") }}
          </el-form-item>
          <el-form-item v-else-if="form.androidHardwareInfo.usb == '1'">
            {{ $t("DeviceManage.Device.BasicNews.Open") }}
          </el-form-item>
          <el-form-item v-else> {{ "N/A" }} </el-form-item>
        </el-form-item>
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.USBdebugging') + '：'"
          v-if="user.osId == 10"
        >
          <el-form-item v-if="form.iosHardwareInfo.usb == '0'">
            {{ $t("DeviceManage.Device.BasicNews.Close") }}
          </el-form-item>
          <el-form-item v-else-if="form.iosHardwareInfo.usb == '1'">
            {{ $t("DeviceManage.Device.BasicNews.Open") }}
          </el-form-item>
          <el-form-item v-else> {{ "N/A" }} </el-form-item>
        </el-form-item>

        <!-- 允许模拟位置 -->
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.Simulatedposition') + '：'"
          v-if="user.osId == 20"
        >
          <el-form-item
            v-if="form.androidHardwareInfo.simulationlocation == '1'"
            >{{ $t("DeviceManage.Device.BasicNews.Open") }}
          </el-form-item>
          <el-form-item
            v-else-if="form.androidHardwareInfo.simulationlocation == '0'"
          >
            {{ $t("DeviceManage.Device.BasicNews.Close") }}
          </el-form-item>
          <el-form-item v-else> {{ "N/A" }} </el-form-item>
        </el-form-item>
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.Simulatedposition') + '：'"
          v-if="user.osId == 10"
        >
          <!-- 以前字段simulationlocation    现在字段 simulatlocation -->
          <el-form-item v-if="form.iosHardwareInfo.simulatlocation == '1'"
            >{{ $t("DeviceManage.Device.BasicNews.Open") }}
          </el-form-item>
          <el-form-item v-else-if="form.iosHardwareInfo.simulatlocation == '0'">
            {{ $t("DeviceManage.Device.BasicNews.Close") }}
          </el-form-item>
          <el-form-item v-else> {{ "N/A" }} </el-form-item>
        </el-form-item>

        <!-- 模拟器 -->
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.Simulator') + '：'"
          v-if="user.osId == 20"
        >
          <el-form-item v-if="form.androidHardwareInfo.vm == '0'"
            >{{ $t("public.Normal") }}
          </el-form-item>
          <el-form-item v-else-if="form.androidHardwareInfo.vm == '1'">
            {{ $t("DeviceManage.Device.BasicNews.Simulator") }}
          </el-form-item>
          <el-form-item v-else>
            {{ $t("DeviceManage.Device.Unknown") }}
          </el-form-item>
        </el-form-item>
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.Simulator') + '：'"
          v-if="user.osId == 10"
        >
          <el-form-item v-if="form.iosHardwareInfo.vm == '0'"
            >{{ $t("public.Normal") }}
          </el-form-item>
          <el-form-item v-else-if="form.iosHardwareInfo.vm == '1'">
            {{ $t("DeviceManage.Device.BasicNews.Simulator") }}
          </el-form-item>
          <el-form-item v-else>
            {{ $t("DeviceManage.Device.Unknown") }}
          </el-form-item>
        </el-form-item>

        <!-- 设备伪造 -->
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.Equipmentforgery') + '：'"
          v-if="user.osId == 20"
        >
          <el-form-item v-if="deviceInfo_bank.fake == '0'"
            >{{ $t("public.Normal") }}
          </el-form-item>
          <el-form-item v-else-if="deviceInfo_bank.fake == '1'">
            {{ $t("DeviceManage.Device.BasicNews.Forge") }}
          </el-form-item>
          <el-form-item v-else>
            {{ "N/A" }}
          </el-form-item>
        </el-form-item>
      </el-form>
    </div>
    <div style="margin-top: 0px">
      <el-form ref="form" :model="form" label-width="300px">
        <!-- 系统时间 1时为异常，0时为正常-->
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.Systemtime') + '：'"
          v-if="user.osId == 20"
        >
          <el-form-item v-if="deviceInfo_bank.systemTime == '0'">
            {{ $t("public.Normal") }}
          </el-form-item>
          <el-form-item v-else-if="deviceInfo_bank.systemTime == '1'">
            {{ $t("DeviceManage.Device.BasicNews.Abnormal") }}
          </el-form-item>
          <el-form-item v-else>
            {{ $t("DeviceManage.Device.Unknown") }}
          </el-form-item>
        </el-form-item>
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.Systemtime') + '：'"
          v-if="user.osId == 10"
        >
          <el-form-item v-if="deviceInfo_bank_ios.systemTime == '0'">
            {{ $t("public.Normal") }}
          </el-form-item>
          <el-form-item v-else-if="deviceInfo_bank_ios.systemTime == '1'">
            {{ $t("DeviceManage.Device.BasicNews.Abnormal") }}
          </el-form-item>
          <el-form-item v-else>
            {{ $t("DeviceManage.Device.Unknown") }}
          </el-form-item>
        </el-form-item>

        <!-- macOS -->
        <el-form-item label="macOS：" v-if="user.osId == 10">
          <el-form-item v-if="deviceInfo_bank_ios.macOS == '0'">
            {{ $t("public.Normal") }}
          </el-form-item>
          <el-form-item v-else-if="deviceInfo_bank_ios.macOS == '1'">
            {{ $t("DeviceManage.Device.BasicNews.Abnormal") }}
          </el-form-item>
          <el-form-item v-else> {{ $t("public.Normal") }} </el-form-item>
        </el-form-item>

        <!-- VPN 1开启，0时为关闭-->
        <el-form-item label="VPN：" v-if="user.osId == 10">
          <el-form-item v-if="deviceInfo_bank_ios.vpn == '0'">
            {{ $t("DeviceManage.Device.BasicNews.Close") }}
          </el-form-item>
          <el-form-item v-else-if="deviceInfo_bank_ios.vpn == '1'">
            {{ $t("DeviceManage.Device.BasicNews.Open") }}
          </el-form-item>
          <el-form-item v-else>
            {{ $t("DeviceManage.Device.BasicNews.Close") }}
          </el-form-item>
        </el-form-item>
        <!-- 锁屏密码 -->
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.Lockscreen') + '：'"
          v-if="user.osId == 20"
        >
          <el-form-item v-if="deviceInfo_bank.screenPassword == '0'">
            {{ $t("DeviceManage.Device.BasicNews.Open") }}
          </el-form-item>
          <el-form-item v-else-if="deviceInfo_bank.screenPassword == '1'">
            {{ $t("DeviceManage.Device.BasicNews.Close") }}
          </el-form-item>
          <el-form-item v-else>
            {{ $t("DeviceManage.Device.Unknown") }}
          </el-form-item>
        </el-form-item>

        <!-- 定制ROM -->
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.CustomROM') + '：'"
          v-if="user.osId == 20"
        >
          <el-form-item v-if="form.androidHardwareInfo.rom == '0'">
            {{ $t("public.Normal") }}
          </el-form-item>
          <el-form-item v-else-if="form.androidHardwareInfo.rom == '1'">
            {{ $t("DeviceManage.Device.BasicNews.CustomROM") }}
          </el-form-item>
          <el-form-item v-else>
            {{ $t("DeviceManage.Device.Unknown") }}
          </el-form-item>
        </el-form-item>

        <!-- Windows -->
        <el-form-item label="Windows：" v-if="user.osId == 20">
          <el-form-item v-if="deviceInfo_bank.windows == '0'">
            {{ $t("public.Normal") }}
          </el-form-item>
          <el-form-item v-else-if="deviceInfo_bank.windows == '1'">
            {{ $t("DeviceManage.Device.BasicNews.Abnormal") }}
          </el-form-item>
          <!-- 未知 -->
          <el-form-item v-else>
            {{ $t("DeviceManage.Device.Unknown") }}
          </el-form-item>
        </el-form-item>

        <!-- VPN -->
        <el-form-item label="VPN：" v-if="user.osId == 20">
          <el-form-item v-if="deviceInfo_bank.vpn == '0'">
            {{ $t("DeviceManage.Device.BasicNews.Close") }}
          </el-form-item>
          <el-form-item v-else-if="deviceInfo_bank.vpn == '1'">
            {{ $t("DeviceManage.Device.BasicNews.Open") }}
          </el-form-item>
          <el-form-item v-else>
            {{ $t("DeviceManage.Device.BasicNews.Close") }}
          </el-form-item>
        </el-form-item>

        <!-- 云手机 -->
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.CloudPhone') + '：'"
          v-if="user.osId == 20"
        >
          <el-form-item v-if="deviceInfo_bank.cloudPhone == '0'">
            {{ $t("public.Normal") }}
          </el-form-item>
          <el-form-item v-else-if="deviceInfo_bank.cloudPhone == '1'">
            {{ $t("DeviceManage.Device.BasicNews.Abnormal") }}
          </el-form-item>
          <!-- 未知 -->
          <el-form-item v-else>
            {{ $t("DeviceManage.Device.Unknown") }}
          </el-form-item>
        </el-form-item>

        <!-- 设备存储空间 -->
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.Devicestorage') + '：'"
          v-if="user.osId == 10"
        >
          <el-form-item v-if="form.iosHardwareInfo">
            {{ form.iosHardwareInfo.devicecapacity }}
          </el-form-item>
        </el-form-item>

        <!-- 设备可用存储空间 -->
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.AvailableStorage') + '：'"
          v-if="user.osId == 10"
        >
          <el-form-item v-if="form.iosHardwareInfo">
            {{ form.iosHardwareInfo.availabledevicecapacity }}
          </el-form-item>
        </el-form-item>

        <!-- 客户端版本 -->
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.Clientversion') + '：'"
          v-if="user.osId == 20"
        >
          <el-form-item v-if="form.androidHardwareInfo">
            {{ form.androidHardwareInfo.clientversion }}
          </el-form-item>
          <el-form-item v-else>
            {{ "N/A" }}
          </el-form-item>
        </el-form-item>
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.Clientversion') + '：'"
          v-if="user.osId == 10"
        >
          <el-form-item v-if="form.iosHardwareInfo.clientInfo">
            {{ clientInfo_ios.version }}
          </el-form-item>
          <el-form-item v-else>
            {{ "N/A" }}
          </el-form-item>
        </el-form-item>

        <!-- 设备管理器 -->
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.DeviceManager') + '：'"
        >
          <!-- 15已激活 20未激活 25设备脱离管控 -->
          <el-form-item v-if="form.isActive == 15">
            {{ $t("DeviceManage.Device.BasicNews.Active") }}
          </el-form-item>
          <el-form-item v-else-if="form.isActive == 20">
            {{ $t("DeviceManage.Device.BasicNews.Notactive") }}
          </el-form-item>
          <el-form-item v-else-if="form.isActive == 25">
            {{ $t("DeviceManage.Device.BasicNews.Outofcontrol") }}
          </el-form-item>
          <el-form-item v-else>
            {{ "N/A" }}
          </el-form-item>
        </el-form-item>

        <!-- 设备厂商 -->
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.Manufacturer') + '：'"
          v-if="user.osId == 20"
        >
          <el-form-item v-if="form.androidHardwareInfo.brand">
            {{ form.androidHardwareInfo.brand }}
          </el-form-item>
          <el-form-item v-else>
            {{ "N/A" }}
          </el-form-item>
        </el-form-item>
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.Manufacturer') + '：'"
          v-if="user.osId == 10"
          >{{ "Apple" }}
        </el-form-item>

        <!-- 设备型号 -->
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.EquipmentModel') + '：'"
          v-if="user.osId == 20"
        >
          <el-form-item v-if="form.androidHardwareInfo.model">
            {{ form.androidHardwareInfo.model }}
          </el-form-item>
          <el-form-item v-else>
            {{ "N/A" }}
          </el-form-item>
        </el-form-item>
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.EquipmentModel') + '：'"
          v-if="user.osId == 10"
        >
          <el-form-item v-if="form.iosHardwareInfo.model">
            {{ form.iosHardwareInfo.model }}
          </el-form-item>
          <el-form-item v-else>
            {{ "N/A" }}
          </el-form-item>
        </el-form-item>

        <!-- 序列号 -->
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.Serialnumber') + '：'"
          v-if="user.osId == 20"
        >
          <!-- androidHardwareInfo 原来  v-if -->
          <el-form-item v-if="form.androidHardwareInfo.serial">
            {{ form.androidHardwareInfo.serial }}
          </el-form-item>
          <el-form-item v-else>
            {{ "N/A" }}
          </el-form-item>
        </el-form-item>
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.Serialnumber') + '：'"
          v-if="user.osId == 10"
        >
          <!-- form.iosHardwareInfo原来  v-if -->
          <el-form-item v-if="form.iosHardwareInfo.serialnumber">
            {{ form.iosHardwareInfo.serialnumber }}
          </el-form-item>
          <el-form-item v-else>
            {{ "N/A" }}
          </el-form-item>
        </el-form-item>

        <!-- 操作系统 -->
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.Operatingsystem') + '：'"
        >
          <el-form-item v-if="form.deviceOs.osType">
            {{ form.deviceOs.osType }}
          </el-form-item>
          <el-form-item v-else>
            {{ "N/A" }}
          </el-form-item>
        </el-form-item>

        <!-- 系统名称 -->
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.Systemname') + '：'"
          v-if="user.osId == 20"
        >
          <el-form-item v-if="hardwareInfo_bank.osName">
            {{ hardwareInfo_bank.osName }}
          </el-form-item>
          <el-form-item v-else>
            {{ "N/A" }}
          </el-form-item>
        </el-form-item>

        <!-- 系统版本 -->
        <el-form-item
          :label="$t('public.SystemVersion') + '：'"
          v-if="user.osId == 20"
        >
          <el-form-item v-if="form.androidHardwareInfo.releaseversion">
            {{ form.androidHardwareInfo.releaseversion }}
          </el-form-item>
          <el-form-item v-else>
            {{ "N/A" }}
          </el-form-item>
        </el-form-item>
        <el-form-item
          :label="$t('public.SystemVersion') + '：'"
          v-if="user.osId == 10"
        >
          <el-form-item v-if="form.iosHardwareInfo.osversion">
            {{ form.iosHardwareInfo.osversion }}
          </el-form-item>
          <el-form-item v-else>
            {{ "N/A" }}
          </el-form-item>
        </el-form-item>

        <!-- 电池电量 -->
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.Batterylevel') + '：'"
          v-if="user.osId == 20"
        >
          <el-form-item v-if="form.androidHardwareInfo.battery">
            {{ form.androidHardwareInfo.battery }}%
          </el-form-item>
          <el-form-item v-else>
            {{ "N/A" }}
          </el-form-item>
        </el-form-item>
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.Batterylevel') + '：'"
          v-if="user.osId == 10"
        >
          <el-form-item v-if="form.iosHardwareInfo.batterylevel">
            {{ form.iosHardwareInfo.batterylevel }}%
          </el-form-item>
          <el-form-item v-else>
            {{ "N/A" }}
          </el-form-item>
        </el-form-item>

        <!-- 内存使用 -->
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.Memoryusage') + '：'"
          v-if="user.osId == 20"
        >
          <el-form-item v-if="form.androidHardwareInfo.availmemorysize">
            {{ form.androidHardwareInfo.availmemorysize }}，{{ memoryUsage }}
          </el-form-item>
          <el-form-item v-else>
            {{ "N/A" }}
          </el-form-item>
        </el-form-item>

        <!-- 上网方式 -->
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.Netplay') + '：'"
          v-if="user.osId == 10"
        >
          {{ $filter.surfInterType(form.iosHardwareInfo.surftype) }}
        </el-form-item>

        <!-- 手机号码 -->
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.Phonenumber') + '：'"
          v-if="user.osId == 10"
        >
          <el-form-item v-if="form.iosHardwareInfo.phoneNumber"
            >{{ form.iosHardwareInfo.phoneNumber }}
          </el-form-item>
          <el-form-item v-else>
            {{ "N/A" }}
          </el-form-item>
        </el-form-item>

        <!-- IMEI -->
        <el-form-item label="IMEI：" v-if="user.osId == 10">
          <!--form.iosHardwareInfo  原来 v-if IMEI   -->
          <el-form-item v-if="form.iosHardwareInfo.imei">
            {{ form.iosHardwareInfo.imei }}
          </el-form-item>
          <el-form-item v-else>
            {{ "N/A" }}
          </el-form-item>
        </el-form-item>

        <!-- UDID -->
        <el-form-item label="UDID：" v-if="user.osId == 10">
          <el-form-item v-if="form.iosHardwareInfo">
            {{ form.iosHardwareInfo.udid }}
          </el-form-item>
          <el-form-item v-else>
            {{ "N/A" }}
          </el-form-item>
        </el-form-item>
      </el-form>
    </div>
    <div style="margin-top: 0px">
      <el-form ref="form" :model="form" label-width="200px">
        <!-- 存储使用 -->
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.Storageusage') + '：'"
          v-if="user.osId == 20"
        >
          <el-form-item v-if="form.androidHardwareInfo.availdatasize">
            {{ form.androidHardwareInfo.availdatasize }}
          </el-form-item>
          <el-form-item v-else>
            {{ "N/A" }}
          </el-form-item>
        </el-form-item>

        <!-- 上网方式 -->
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.Netplay') + '：'"
          v-if="user.osId == 20"
        >
          {{ $filter.surfInterType(form.androidHardwareInfo.surftype) }}
        </el-form-item>

        <!-- 手机号码 -->
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.Phonenumber') + '：'"
          v-if="user.osId == 20"
        >
          <el-form-item v-if="phonumber">
            {{ phonumber }}
          </el-form-item>
          <el-form-item v-else>
            {{ "N/A" }}
          </el-form-item>
        </el-form-item>

        <!-- SDK版本 -->
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.SDKversion') + '：'"
          v-if="user.osId == 20"
        >
          <el-form-item v-if="form.androidHardwareInfo.sdk">
            {{ form.androidHardwareInfo.sdk }}
          </el-form-item>
          <el-form-item v-else>
            {{ "N/A" }}
          </el-form-item>
        </el-form-item>

        <!-- 时区 -->
        <el-form-item
          :label="$t('DeviceManage.Device.BasicNews.Timezone') + '：'"
          v-if="user.osId == 20"
        >
          <el-form-item v-if="deviceInfo_bank.timeZone">
            {{ deviceInfo_bank.timeZone }}
          </el-form-item>
          <el-form-item v-else>
            {{ "N/A" }}
          </el-form-item>
        </el-form-item>

        <!-- IMEI -->
        <el-form-item label="IMEI：" v-if="user.osId == 20">
          <!-- form.androidHardwareInfo -->
          <el-form-item v-if="form.androidHardwareInfo.deviceid">
            {{ form.androidHardwareInfo.deviceid }}
          </el-form-item>
          <el-form-item v-else>
            {{ "N/A" }}
          </el-form-item>
        </el-form-item>

        <!-- UDID -->
        <el-form-item label="UDID：" v-if="user.osId == 20">
          <el-form-item v-if="form.androidHardwareInfo">
            {{ form.androidHardwareInfo.udid }}
          </el-form-item>
          <el-form-item v-else>
            {{ "N/A" }}
          </el-form-item>
        </el-form-item>

        <!-- AndroidID -->
        <el-form-item label="AndroidID：" v-if="user.osId == 20">
          <el-form-item v-if="hardwareInfo_bank.androidId">
            {{ hardwareInfo_bank.androidId }}
          </el-form-item>
          <el-form-item v-else>
            {{ "N/A" }}
          </el-form-item>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //内存使用率
      memoryUsage: "",
      form: {
        androidHardwareInfo: {
          availdatasize: "",
          availmemorysize: "",
          battery: "",
          board: "",
          bootLoader: "",
          brand: "",
          clientversion: "",
          codeName: "",
          cpuAbi: "",
          cpuAbi2: "",
          dataState: "",
          device: "",
          deviceId: "",
          deviceSoftwareVersion: "",
          display: "",
          fingerPrint: "",
          groupIdLevel1: "",
          hardware: "",
          hasIccCard: "",
          host: "",
          incremental: "",
          manufacturer: "",
          mmsUAProfUrl: "",
          mmsUserAgent: "",
          model: "",
          netWorkType: "",
          networkCountryIso: "",
          networkOperatorName: "",
          networkRoaming: "",
          os: "",
          phoneType: "",
          product: "",
          radio: "",
          releaseVersion: "",
          releaseVersionId: "",
          sdk: "",
          sdkInt: "",
          serial: "",
          simCountryIso: "",
          simOperator: "",
          simOperatorName: "",
          simSerialNumber: "",
          simState: "",
          subscriberId: "",
          surfType: "",
          tags: "",
          time: "",
          timeStr: "",
          totalDataSize: "",
          totalMemorySize: "",
          tuser: "",
          type: "",
          udid: "",
          updateTime: "",
          voiceMailAlphaTag: "",
          voiceMailNumber: "",
        },
        iosHardwareInfo: {
          availabledevicecapacity: "",
          batterylevel: "",
          bluetooth: "",
          bluetoothmac: "",
          buildversion: "",
          carriersettingsversion: "",
          cellulartechnology: "",
          currentcarriernetwork: "",
          currentmcc: "",
          currentmnc: "",
          datalink: "",
          dataroamingenabled: "",
          devicecapacity: "",
          devicename: "",
          hotspot: "",
          iccid: "",
          imei: "",
          isroaming: "",
          location: "",
          meid: "",
          model: "",
          modelname: "",
          modemfirmwareversion: "",
          osversion: "",
          phonenumber: "",
          productname: "",
          rom: "",
          root: "",
          serialnumber: "",
          simcarriernetwork: "",
          simmcc: "",
          simmnc: "",
          simulatlocation: "",
          subscribercarriernetwork: "",
          subscribermcc: "",
          subscribermnc: "",
          surftype: "",
          udid: "",
          updatetime: "",
          usb: "",
          vm: "",
          voiceroamingenabled: "",
          wifi: "",
          wifimac: "",
        },
        androidSoftwareInfo: {
          appCacheSize: "",
          appDataSize: "",
          appName: "",
          appSize: "",
          askStatus: "",
          firstInstallTime: "",
          id: "",
          isSystem: "",
          lastUpdateTime: "",
          lastUpdateTimeStr: "",
          packageName: "",
          purgeStatus: "",
          scanScore: "",
          udid: "",
          updateTime: "",
          versionCode: "",
          versionName: "",
        },
        assistantAppStatus: "",
        belong: "",
        brand: "",
        deviceConIMStatus: {
          conStatus: "",
          id: "",
          lastLoginTime: "",
          lastUpdateTime: "",
          udid: "65e39a0131d78f1983c785c7e60d70e7561a2d9d",
        },
        createTime: "",
        deviceGroup: {
          fullName: "",
        },
        deviceName: "",
        deviceOs: {
          id: "",
          osType: "",
        },
        enrollmentTime: "",
        hardwareUpdateTime: "",
        id: "",
        installed: "",
        isActive: "",
        isJailBreak: "",
        isViolation: "",
        mode: "",
        model: "",
        signLongOffline: "",
        signStatus: "",
        status: "",
        bindStatus: "",
        surfType: "",
        uninstallCode: "",
        violationreason: "",
      },
      user: {
        osId: "",
      },
      clientInfo_ios: "", //ios客户端版本
      // 农行新增
      deviceInfo_bank: {},
      hardwareInfo_bank: {},
      deviceInfo_bank_ios: {}, //
      // 手机号
      phonumber: "",
    };
  },
  created() {
    // let user = JSON.parse(window.sessionStorage.getItem("user"));
    // this.getlist(user);
  },
  mounted() {
    setTimeout(() => {
      let user = JSON.parse(window.sessionStorage.getItem("user"));
      this.user = user;
      this.getlist(user);
    }, 1000);
  },

  methods: {
    async getlist(user) {
      // console..log(user)
      var param = {
        deviceId: user.id.toString(),
        osType: user.osId == 20 ? "Android" : "iOS",
        deviceUDID: user.deviceUDID,
        // deviceId: user.id.toString(),
        // deviceUDID: user.deviceUDID,
        // osType: user.osId == 20 ? "Android" : "iOS",
        // status: user.status.toString(),
      };
      // console..log(param)
      // const res = await this.$axios.post("/api/device/basedeviceInfo.do", param, true);
      const res = await this.$axios.post(
        "/httpServe/device/baseDeviceInfo",
        param,
        true
      );
      this.form = res.data;
      console.log("获取数据res.data：",res.data, );
      console.log("获取数据this.form：",this.form );

      if (res.data == null) {
        this.form = {};
      }
      if (user.osId == 20) {
        if (res.data.androidHardwareInfo) {
          this.phonumber = this.form?.androidHardwareInfo?.line1number;
          if (res.data.androidHardwareInfo.deviceInfo) {
            this.deviceInfo_bank = Object.assign(
              eval("(" + res.data.androidHardwareInfo.deviceInfo + ")")
            );
          }

          if (res.data.androidHardwareInfo.hardwareInfo) {
            this.hardwareInfo_bank = Object.assign(
              eval("(" + res.data.androidHardwareInfo.hardwareInfo + ")")
            );
            console.log(this.hardwareInfo_bank, "  this.hardwareInfo_bank");
          }
        }

        // // //内存使用数
        if (res.data.androidHardwareInfo.availmemorysize != null) {
          var availmemorysize =
            ((this.form.androidHardwareInfo.availmemorysize /
              1024 /
              1024 /
              1024) *
              10) /
            10.0;
          var num = new Number(availmemorysize);
          // 内存总条数
          var totalmemorysize =
            ((this.form.androidHardwareInfo.totalmemorysize /
              1024 /
              1024 /
              1024) *
              10) /
            10.0;
          var newnum = new Number(totalmemorysize);
          // 内存使用率

          this.memoryUsage = this.$common.getPercent(num, newnum);

          this.form.androidHardwareInfo.availmemorysize =
            num.toFixed("1") + "GB/" + newnum.toFixed("1") + "GB";
          // console..log(this.form.androidHardwareInfo.availmemorysize, '内存使用')
        } else if (res.data.androidHardwareInfo.availmemorysize == null) {
          this.form.androidHardwareInfo.availmemorysize =
            "0.0" + "GB/" + "0.0" + "GB";
        }
        // 存储使用
        if (res.data.androidHardwareInfo.availdatasize != null) {
          // 存储使用
          var availdatasize =
            ((this.form.androidHardwareInfo.availdatasize /
              1024 /
              1024 /
              1024) *
              10) /
            10.0;
          var avail = new Number(availdatasize);
          // 存储总数
          var totaldatasize =
            ((this.form.androidHardwareInfo.totaldatasize /
              1024 /
              1024 /
              1024) *
              10) /
            10.0;
          var totalnum = new Number(totaldatasize);
          this.form.androidHardwareInfo.availdatasize =
            avail.toFixed("1") + "GB/" + totalnum.toFixed("1") + "GB";
          // console..log(this.form.androidHardwareInfo.availdatasize, '存储使用')
          // this.$forceUpdate()
        } else if (res.data.androidHardwareInfo.availdatasize == null) {
          this.form.androidHardwareInfo.availdatasize =
            "0.0" + "GB/" + "0.0" + "GB";
        }
      } else if (user.osId == 10) {
        if (res.data.iosHardwareInfo) {
          console.log(
            "客户端版本ios处理前:",
            res.data.iosHardwareInfo.clientInfo
          );
          if (res.data.iosHardwareInfo.clientInfo) {
            this.clientInfo_ios = Object.assign(
              eval("(" + res.data.iosHardwareInfo.clientInfo + ")")
            );
            console.log("客户端版本ios处理之后:", this.clientInfo_ios);
          }
          if (res.data.iosHardwareInfo.deviceInfo) {
            this.deviceInfo_bank_ios = Object.assign(
              eval("(" + res.data.iosHardwareInfo.deviceInfo + ")")
            );
            console.log("系统时间ios处理之后:", this.deviceInfo_bank_ios);
          }
        }
        console.log(res.data, "ios详情新增字段");
        // //设备内存使用数
        var devicecapacity =
          ((this.form.iosHardwareInfo.devicecapacity / 1024 / 1024 / 1024) *
            10) /
          10.0;
        var nums = new Number(devicecapacity);
        this.form.iosHardwareInfo.devicecapacity = nums.toFixed("1") + "GB";
        console.log(this.form.iosHardwareInfo.devicecapacity, "设备内存使用");

        // 设备存储使用
        var availabledevicecapacity =
          ((this.form.iosHardwareInfo.availabledevicecapacity /
            1024 /
            1024 /
            1024) *
            10) /
          10.0;
        var avails = new Number(availabledevicecapacity);
        this.form.iosHardwareInfo.availabledevicecapacity =
          avails.toFixed("1") + "GB";
        console.log(
          this.form.iosHardwareInfo.availabledevicecapacity,
          "设备存储使用"
        );
        this.$forceUpdate();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 0px !important;
}

.el-form-item__label {
  color: #000000 !important;
}

.wrap .group .msg_l0 {
  color: #807e7e !important;
}
</style>
<style></style>
