<template>
  <div style="height: 700px; overflow: auto">
    <!-- 20为安卓   10为ios -->
    <el-table size="small" stripe fit ref="multipleTable" tooltip-effect="dark" class="sort_table" style="width: 99%; margin: 8px" :header-cell-style="{ background: '#f7f8fa', color: '#606266' }" :data="form">
      <!-- 序号 -->
      <el-table-column :label="$t('DeviceManage.Device.Application.SerialNumber')" width="80" :show-overflow-tooltip="true" type="index" prop="userInfo.userName">
      </el-table-column>

      <!-- 文件名称  -->
      <el-table-column :label="$t('DeviceManage.Device.Application.FileName')" :show-overflow-tooltip="true" width="290" prop="name">
        <template v-slot="{ row }">
          <template v-if="row.name"> {{row.name}} </template>
          <template v-else> N/A </template>
        </template>
      </el-table-column>

      <!-- 版本 -->
      <el-table-column :label="$t('DeviceManage.Device.Installationapp.Edition')" :show-overflow-tooltip="true" width="240" prop="versionName">
        <template v-slot="{ row }">
          <template v-if="row.versionName"> {{row.versionName}} </template>
          <template v-else> N/A </template>
        </template>
      </el-table-column>

      <!-- 安装路径 -->
      <el-table-column :label="$t('DeviceManage.Device.Application.Installationpath')" :show-overflow-tooltip="true" width="290" prop="installPath">
        <template v-slot="{ row }">
          <template v-if="row.installPath"> {{row.installPath}} </template>
          <template v-else> N/A </template>
        </template>
      </el-table-column>

      <!-- 安装时间 -->
      <el-table-column :label="$t('DeviceManage.Device.Application.Installationtime')" :show-overflow-tooltip="true" width="290" prop="installTime">
        <template v-slot="{ row }">
          <template v-if="row.installTime"> {{row.installTime}} </template>
          <template v-else> N/A </template>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: [],
      user: {
        osId: ''
      }
    }
  },

  created() {},

  mounted() {
    let user = JSON.parse(window.sessionStorage.getItem('user'))
    this.user = user
    setTimeout(() => {
      console.log(this.user.osId)
    }, 3000)
    this.getlist(user)
  },

  methods: {
    async getlist(user) {
      var param = {
        deviceId: user.id,
        deviceUDID: user.deviceUDID,
        osType: user.osId==30?'Windows':'Linux',
      }
      // console.log(data);
      const res = await this.$axios.post(
        'httpServe/webSoftware/deviceSoftwareInfo',
        param,
        true
      )
      this.form = res.data
    }
  }
}
</script>

<style lang="scss" scoped></style>
