<template>
  <div>
    <el-tree
      :load="loadNode"
      lazy
      ref="tree"
      :data="data"
      :props="props"
      @node-click="handleNodeClick"
      node-key="id"
      :default-expanded-keys="defaultExpanded"
      :current-node-key="currentNodekey"
      :highlight-current="true"
      @check-change="handleCheckChange"
    >
      <span slot-scope="{ node, data }">
        <span
          :title="node.label"
          :label="data.id"
          class="show-ellipsis"
          :disabled="flag && node.data.id == defaultExpanded"
        >
          {{ node.label }}
        </span>
      </span>
    </el-tree>
  </div>
</template>

<script>
export default {
  props: {
    flag: {
      type: Boolean,
      default: true,
    },
    trees: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      userId: "",
      nodesId: null,
      data: [],
      defaultExpanded: [sessionStorage.getItem("manageGroupId")],
      currentNodekey: sessionStorage.getItem("manageGroupId"),
      props: {
        //组织树props 提前告知子节点是否还有加载 isLeaf
        label: "name",
        children: "data",
        isLeaf: "isParent",
      },
      num: 0,
    };
  },
  created() {
    this.handleNodeClick({ id: sessionStorage.getItem("manageGroupId") }); //
  },
  methods: {
    //点击tree节点事件
    handleNodeClick(node, data, value) {
      console.log(node, "--node");
      this.num++;
      let pid = node.pid;
      this.$emit("tree", node.id, pid, this.num);
    },
    // 树列表
    async loadNode(node, resolve) {
      console.log(node, resolve);
      this.nodesId = node;
      this.parentId = node.data.id 
      let token2 = sessionStorage.getItem("Authorization")
        ? sessionStorage.getItem("Authorization")
        : "eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFM1MTIifQ.eyJleHAiOjE2MzMwMjMzNzUsInVzZXJJZCI6MSwiaWF0IjoxNjMzMDAxNzc1fQ.hSF4bNDHVFZR2h4R0osNBF56ChR_l03mwx9QYaS1E0JDtIFlme3dP8oktCjEIaGi4QzXutLRMmjFWxuDNtaMMA";
      let userString = decodeURIComponent(
        escape(window.atob(token2.split(".")[1]))
      );
      this.userId = JSON.parse(userString).userId;
      var param = {
        parentId: node.data ? node.data.id : "",
        userId: this.userId,
      };
      const res = await this.$axios.post(
        "/httpServe/tree/selectNode",
        param,
        true
      );
      console.log(param, "param");
      res.data.content.forEach((item, index) => {
        if (item.isParent == "true") {
          item.isParent = false;
        } else {
          item.isParent = true;
        }
      });
      resolve(res.data.content);
    },
    show() {
      console.log(aaaaa);
    },
    //树状图节点选中状态发生变化时的回调
    handleCheckChange(data, checked, indeterminate) {
      this.checked = checked;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
