<template>
  <div style="height:700px; overflow: auto;">
    <el-table size="small" stripe fit ref="multipleTable" tooltip-effect="dark" class="sort_table" style="width: 99%; margin: 8px" :header-cell-style="{ background: '#f7f8fa', color: '#606266' }" :data="form">
      <el-table-column :label="$t('DeviceManage.Device.Policyinfo.Policyname')" :show-overflow-tooltip="true" width="155" prop="clientConfig.name">
      </el-table-column>
      <el-table-column :label="$t('public.Section')" :show-overflow-tooltip="true" width="290" prop="groups.groupFullName">
        <template v-slot="{ row }">
          <span v-if="row.groups.groupFullName">
            {{ row.groups.groupFullName.substr(groupFullNameShowByIndex) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('DeviceManage.Device.Policyinfo.Policyclassification')" :show-overflow-tooltip="true" width="290" prop="policyType">
        <template v-slot="{ row }">
          <template v-if="row.policyType == 2"> {{$t('DeviceManage.Device.Policyinfo.Compliancestrategy')}} </template>
          <template v-if="row.policyType == 1"> {{$t('DeviceManage.Device.Policyinfo.Devicepolicy')}} </template>
        </template>
      </el-table-column>
      <el-table-column :label="$t('public.OperatingSystem')" :show-overflow-tooltip="true" width="290" prop="osType">
        <template v-slot="{ row }">
          <template v-if="row.osType == 20"> Android </template>
          <template v-else> iOS </template>
        </template>
      </el-table-column>

      <el-table-column :label="$t('DeviceManage.Device.Policyinfo.Updatetime')" :show-overflow-tooltip="true" width="290" prop="createTimeStr">
      </el-table-column>
      <el-table-column :label="$t('DeviceManage.Device.Policyinfo.Policystate')" :show-overflow-tooltip="true" prop="status">
        <template v-slot="{ row }">
          <template v-if="row.status == 0"> {{$t('public.Assigned')}} </template>
          <template v-else-if="row.status == 1"> {{$t('public.Executed')}} </template>
          <!-- <template v-else> 未分配 </template> -->
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  data() {
    return {
      groupFullNameShowByIndex: "", //用户部门截取的下标
      form: []
    }
  },

  created() {
    let user = JSON.parse(window.sessionStorage.getItem('user'))
    this.getlist(user)
  },

  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem("groupFullNameShowByIndex");
  },

  methods: {
    async getlist(user) {
      var param = {
        deviceId: user.id,
        deviceUDID: user.deviceUDID,
        osType: user.osId == 20 ? 'Android' : 'iOS'
        // status: user.status,
        // rowNumber: 10,
      }
      // console.log(data);
      const res = await this.$axios.post(
        // "api/device/policy/devicePolicyInfo.do",
        'httpServe/device/policy/devicePolicyInfo',
        param,
        true
      )
      console.log(
        moment(res.data.createTime).format('YYYY-MM-DD HH:mm:ss'),
        'llllll'
      )
      res.data.createTime = moment(res.data.createTime).format(
        'YYYY-MM-DD HH:mm:ss'
      )
      this.form = res.data
    }
  }
}
</script>

<style lang="scss" scoped></style>
