<template>
  <div>
    <div class="daochu">
      <div class="jiben">
        <div class="news">{{$t('DeviceManage.Device.Basicinformation')+'：'}}</div>
        <div style="margin-left: 30px;width: 450px;">
          <el-checkbox-group v-model="checkList" @change="checkListChangedis">
            <el-checkbox label="deviceName" disabled>{{$t('public.DeviceName')}}</el-checkbox>
            <!-- 设备归属 -->
            <el-checkbox label="belong">{{$t('DeviceManage.Device.Equipmentownership')}}</el-checkbox> 
            <el-checkbox label="deviceTime">{{$t('DeviceManage.Device.Deviceexport.Onoffline')}}</el-checkbox>
          </el-checkbox-group>
          <!-- <el-checkbox-group v-model="checkList">
            <el-checkbox label="deviceTime">在/离线时长</el-checkbox>
          </el-checkbox-group> -->
          <el-checkbox-group v-model="checkList"> 
            <el-checkbox label="healthDegree">{{$t('DeviceManage.Device.Healthdegree')}}</el-checkbox>
            <!-- v6.1.3需求 -->
            <el-checkbox label="deviceGroupName">设备组</el-checkbox> 
            <!-- v6.1.2需求 -->
            <el-checkbox label="activeTime">激活时间</el-checkbox> 
            
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="isViolation">{{$t('DeviceManage.Device.Compliancestatus')}}</el-checkbox>
            <el-checkbox label="brand">{{$t('DeviceManage.Device.BasicNews.Manufacturer')}}</el-checkbox>
            <el-checkbox label="lastLoginTime">{{$t('DeviceManage.Device.lastLoginTime')}}</el-checkbox> 
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="isJailBreak">{{$t('DeviceManage.Device.BasicNews.Devicejailbreak')}}</el-checkbox>
            <el-checkbox label="model">{{$t('DeviceManage.Device.BasicNews.EquipmentModel')}}</el-checkbox>

          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="status">{{$t('DeviceManage.Device.EquipmentStatus')}}</el-checkbox> 
            <el-checkbox label="serial">{{$t('DeviceManage.Device.BasicNews.Serialnumber')}}</el-checkbox>

          </el-checkbox-group>
          <!-- 流程状态 -->
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="askStatus">{{$t('DeviceManage.Device.askStatus')}}</el-checkbox> 
            <el-checkbox label="osId">{{$t('public.OperatingSystem')}}</el-checkbox>

          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="bindStatus">{{$t('DeviceManage.Device.Bindingstate')}}</el-checkbox>
            <el-checkbox label="conStatus">{{$t('DeviceManage.Device.Onlinesta')}}</el-checkbox> 

          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="osVersion">{{$t('public.SystemVersion')}}</el-checkbox>
            <el-checkbox label="tunnelStatus">{{$t('DeviceManage.Device.Tunnelstatus')}}</el-checkbox> 

          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="surfType">{{$t('DeviceManage.Device.BasicNews.Netplay')}}</el-checkbox>
            <el-checkbox label="battery">{{$t('DeviceManage.Device.BasicNews.Batterylevel')}}</el-checkbox> 

          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="number">{{$t('DeviceManage.Device.BasicNews.Phonenumber')}}</el-checkbox>
            <el-checkbox label="availMemorySize">{{$t('DeviceManage.Device.BasicNews.Memoryusage')}}</el-checkbox> 

          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="sdk">{{$t('DeviceManage.Device.BasicNews.SDKversion')}}</el-checkbox>
            <el-checkbox label="availDataSize">{{$t('DeviceManage.Device.BasicNews.Storageusage')}}</el-checkbox> 

          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="imei">IMEI</el-checkbox>
            <el-checkbox label="version">{{$t('DeviceManage.Device.BasicNews.Clientversion')}}</el-checkbox> 

          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="deviceUDID">UDID</el-checkbox>
            <el-checkbox label="isActive">{{$t('DeviceManage.Device.BasicNews.DeviceManager')}}</el-checkbox> 

          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="signStatus">{{$t('DeviceManage.Device.Signstate')}}</el-checkbox>
            <el-checkbox label="deviceDay">{{$t('DeviceManage.Device.Deviceexport.OnofflineDays')}}</el-checkbox> 

          </el-checkbox-group>
        </div>
      </div>
      <div class="jibens">
        <div class="news">{{$t('DeviceManage.Device.HardwareInformation')+'：'}}</div>
        <div style="margin-left: 30px;width: 450px;">
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="phoneType">{{$t('DeviceManage.Device.Hardware.Gsm')}}</el-checkbox>
            <el-checkbox label="board">{{$t('DeviceManage.Device.Hardware.Motherboard')}}</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="netWorkType">{{$t('DeviceManage.Device.Hardware.Network')}}</el-checkbox>
            <el-checkbox label="releaseVersionId">{{$t('public.Version')}}</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="device">{{$t('DeviceManage.Device.Hardware.Deviceparameter')}}</el-checkbox>
            <el-checkbox label="hasIccCard">{{$t('DeviceManage.Device.Hardware.HaveaSIMcard')}}</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="product">{{$t('DeviceManage.Device.Hardware.Phonefirm')}}</el-checkbox>
            <el-checkbox label="simCountryIso">{{$t('DeviceManage.Device.Hardware.SIMcardcountry')}}</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="hardware">{{$t('DeviceManage.Device.Hardware.HardwareName')}}</el-checkbox>
            <el-checkbox label="simState">{{$t('DeviceManage.Device.Hardware.SIMcardstatus')}}</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="deviceSoftwareVersion">{{$t('DeviceManage.Device.Hardware.Softwareversion')}}</el-checkbox>
            <el-checkbox label="simSerialNumber">{{$t('DeviceManage.Device.Hardware.SIMcardserial')}}</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="subscriberId">{{$t('DeviceManage.Device.Hardware.MobileUserID')}}</el-checkbox>
            <el-checkbox label="simOperatorName">{{$t('DeviceManage.Device.Hardware.SIMcardsupplier')}}</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="hardwarBrand">{{$t('DeviceManage.Device.Hardware.Hardwaremanufacturer')}}</el-checkbox>
            <el-checkbox label="timeStr">{{$t('DeviceManage.Device.Hardware.BUILDtime')}}</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="systemBrand">{{$t('DeviceManage.Device.Hardware.SystemCustomizer')}}</el-checkbox>
            <el-checkbox label="tags">{{$t('DeviceManage.Device.Hardware.BUILDdescription')}}</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="hardwarSerial">{{$t('DeviceManage.Device.Deviceexport.Goodhardware')}}</el-checkbox>
            <el-checkbox label="cpuAbi">{{$t('DeviceManage.Device.Deviceexport.CPUinstructionset')}}</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="voiceMailNumber">{{$t('DeviceManage.Device.Hardware.Voicemessage')}}</el-checkbox>
            <el-checkbox label="cpuAbi2">{{$t('DeviceManage.Device.Deviceexport.CPUinstructiontwo')}}</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="networkOperatorName">{{$t('DeviceManage.Device.Hardware.OperatorName')}}</el-checkbox>
            <el-checkbox label="deivceId">{{$t('DeviceManage.Device.Hardware.MobileDeviceID')}}</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="networkCountryIso">{{$t('DeviceManage.Device.Hardware.Suppliercountry')}}</el-checkbox>
            <el-checkbox label="releaseVersion">{{$t('DeviceManage.Device.Hardware.Visibleversionstring')}}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="jibenss">
        <div class="news">{{$t('DeviceManage.Device.Deviceexport.Userinformation')+'：'}}</div>
        <div style="margin-left: 30px;width: 450px;">
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="loginName">{{$t('public.Username')}}</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="userName">{{$t('public.Name')}}</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="userSex">{{$t('DeviceManage.Device.Deviceexport.Gender')}}</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="groupFullName">{{$t('public.Section')}}</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="phone">{{$t('public.Phone')}}</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="email">{{$t('public.Email')}}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
    </div>
    <el-button
      type="primary"
      size="small"
      style="margin: 20px 180px; width: 80px"
      @click="daochu"
      >{{$t('public.Derive')}}</el-button
    >
  </div>
</template>

<script>
export default {
  props: {
    searchValArr: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      userId: "1",
      checkList: [
        "deviceName",
        "isViolation",
        "status",
        "askStatus",
        "bindStatus",
        "conStatus",
        "deviceDay",
        "deviceTime",
        "brand",
        "model",
        "osId",
        "osVersion",
        "surfType",
        "imei",
        "belong",
        "lastLoginTime",
        "networkOperatorName",
        "loginName",
        "userName",
        "groupFullName",
        "phone",
        "email",
        "tunnelStatus",
      ],
      form: {
        deviceName: "",
      },
      searchname:"",
      placeholder:"",
      devosType:""
    };
  },

  mounted() {
    let searchname = window.sessionStorage.getItem("searchname");
    this.searchname=searchname;
    //  console.log(window.sessionStorage.getItem("searchname"))

    let placeholder = window.sessionStorage.getItem("placeholder");
    console.log(placeholder,"let");
    this.placeholder=placeholder
    console.log(placeholder,"let2");
    // console.log(window.sessionStorage.getItem("placeholder"))
  },

  methods: {
    checkListChangedis(val){
      console.log(val)

    },
    async daochu() {
    // async daochu(seaname,plaholde) {
      // console.log("下载模板");
      let token2 =sessionStorage.getItem("Authorization") ?sessionStorage.getItem("Authorization"):
        "eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFM1MTIifQ.eyJleHAiOjE3NTczMTU3NDMsInVzZXJJZCI6MiwiaWF0IjoxNjQ5MzE1NzQzfQ.ig-bUpmMVNvC1L5XAoVmC_wIWJD-gG_L1l8NnQLTJwTZ-2hkJP1eBYtc3qF457bbvjtWKMWoXVz07ots4Faa5A";
      let userString = decodeURIComponent(escape(window.atob(token2.split(".")[1])));
      // let userString = decodeURIComponent(
      //   escape(window.atob(this.token.split(".")[1]))
      // );
      this.userId = JSON.parse(userString).userId;
      console.log(this.userId, "ididiididi");
      if(this.$route.query.jumpPageName){
        console.log(this.$route.query.searchKey,"this.$route.query.searchKey")
        this.placeholder='osVersion'
      }
      console.log(this.searchValArr,"this.searchValArr")
      var params = {
        currentPage: this.searchValArr.pageNumber,
        pageSize: this.searchValArr.rowNumber,
        deviceStatus: this.searchValArr.deviceStatus,
        isViolation: this.searchValArr.isViolation,
        signStatus: this.searchValArr.signStatus,
        osType: this.searchValArr.osType,
        belong: this.searchValArr.belong,
        conStatus: this.searchValArr.conStatus,
        conStateRate: this.searchValArr.conStateRate,
        orderColume: "devcon.lastLoginTime",
        orderRule: "DESC",
          osType:this.searchValArr.osType,
        searchKey: this.searchValArr.searchKey, //设备名称 下拉框 姓名 用户名 部门 //搜索下拉框
        searchContent: this.searchValArr.input, //搜索内容
        exportField:this.checkList
      };
      console.log(params);
      const res = await this.$axios.postExport(
        "/httpManageExport/device/exportFile",
        params,
        false,
        this.userId
      );
      // debugger
      // const res = await this.$axios.post("/httpServe/device/exportFile", params, true);
      console.log(res);
      // const res = await this.$axios.get("/api/device/exportFile.do", true);
      // await this.$axios.downloadFile(res.data);
      const link = document.createElement("a");
      console.info(new Date());

      // let fileName = res.headers["content-disposition"].split("=")[1];
      var fileName = "exportDevice_" + Date.parse(new Date()) + ".xls";
      // console.log(fileName);
      let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
      link.style.display = "none";
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", decodeURI(fileName));
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

<style lang="scss" scoped>
.daochu {
  margin-top: 10px;
  display: flex;
}
.jiben {
  margin-left: 7%;
  width: 460px;
}
.jibens {
  margin-left: 8%;
  width: 556px;
}
.jibenss {
  margin-left: 8%;
  width: 556px;
}
.news {
  font-size: 14px;
  margin-top: 20px;
}
.el-checkbox {
  width: 130px;
  padding: 2%;
}
::v-deep .el-radio__label {
  font-size: 12px;
  color: #807e7e;
}
::v-deep .el-checkbox__label {
  font-size: 14px;
  color: #807e7e;
}
</style>