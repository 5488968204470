<template>
  <!-- 按部门分配 -->
  <div>
    <el-row :gutter="30" class="box" ref="box" style="padding: 5px 5px 5px 15px">
      <el-col :span="12">
        <!-- 左侧部分 -->
        <div class="leftBox" ref="leftBox">
          <!-- 树状图部分 -->
          <div class="leftTree" style="max-width: 60%">
            <!-- tree -->
            <div class="treeBox">
              <Tree @tree="trees" />
            </div>
            <!-- 拖拽图标 -->
            <div class="dragPoint" style="right: -11px">
              <i>
                <img src="../../../images/icon_drag.png" alt="" />
                <!-- <img src="../../../../images/icon_drag.png" alt="" /> -->
              </i>
            </div>
          </div>
          <!-- 表格部分 -->
          <div class="leftTable">
            <!-- 表格 -->
            <left-table ref="left_table"></left-table>
          </div>
        </div>
      </el-col>
      <!-- 右侧已选择部分 -->
      <!-- <el-col :span="7" class="rightBox">
        <right-table :rightObj="rightData"></right-table>
      </el-col> -->
    </el-row>
  </div>
</template>

<script>
import rightTable from "./RightTable";
import leftTable from "./LeftTable";
import Tree from '@/components/treeSulation/userTree.vue' //树形组织
// import Tree from '@/components/treeSulation/treeEncapsulation.vue' //树形组织
export default {
  components: { rightTable, leftTable,Tree },
  data() {
    return {
      searchGroup: "", //树状图中查询部门的查询内容
      checked: false, // tree是否有节点选中
      treeData: [],
      default_expand: [],
      defaultProps: {
        children: "children",
        label: "name",
        children: "data",
        // isLeaf: 'false',
        isLeaf: "isParent",
      },
      rightData: {}, //右侧表格数据展示
      currentNodeKey: "1",
      defaultExpandedKeys: [1], //树状图默认展开的节点数据
    };
  },
  mounted() {
    this.dragControllerDiv();
    // this.handleNodeClick({ id: "0" }); //G_1
    this.$refs.left_table.getList(this.defaultExpandedKeys)
    // this.getLeftTree();
  },
  methods: {
    // 悬浮显示全部文字

    //树状图查询部门
    onSearch() {},
    //树状图外部盒子拖拽
    dragControllerDiv: function () {
      var dragPoint = document.getElementsByClassName("dragPoint");
      var leftTree = document.getElementsByClassName("leftTree");
      var leftTable = document.getElementsByClassName("leftTable");
      var leftBox = document.getElementsByClassName("leftBox");
      for (let i = 0; i < dragPoint.length; i++) {
        // 鼠标按下事件
        dragPoint[i].onmousedown = function (e) {
          //颜色改变提醒
          // dragPoint[i].style.background = "#818181";
          var startX = e.clientX; //鼠标按下时鼠标指针的横向坐标
          dragPoint[i].leftTree = dragPoint[i].offsetLeft;
          // 鼠标拖动事件
          document.onmousemove = function (e) {
            console.log(e, "eeeee");
            var endX = e.clientX; //鼠标拖动时鼠标指针的横向坐标
            var moveLen = dragPoint[i].leftTree + (endX - startX); // （endx-startx）=移动的距离。dragPoint[i].left+移动的距离=左边区域最后的宽度
            var maxT = leftBox[i].clientWidth - dragPoint[i].offsetWidth; // 容器宽度 - 左边区域的宽度 = 右边区域的宽度
            if (moveLen < 232) moveLen = 232; // 左边区域的最小宽度为232px
            if (moveLen > maxT - 150) moveLen = maxT; //右边区域最小宽度为150px
            dragPoint[i].style.leftTree = moveLen; // 设置左侧区域的宽度
            for (let j = 0; j < leftTree.length; j++) {
              leftTree[j].style.width = moveLen + "px";
              // leftTable[j].style.width =
              leftBox[i].clientWidth - moveLen - 10 + "px";
            }
          };
          // 鼠标松开事件
          document.onmouseup = function (evt) {
            //颜色恢复
            // dragPoint[i].style.background = "#d6d6d6";
            document.onmousemove = null;
            document.onmouseup = null;
            dragPoint[i].releaseCapture && dragPoint[i].releaseCapture(); //当你不在需要继续获得鼠标消息就要应该调用ReleaseCapture()释放掉
          };
          dragPoint[i].setCapture && dragPoint[i].setCapture(); //该函数在属于当前线程的指定窗口里设置鼠标捕获
          return false;
        };
      }
    },
    //树状图节点选中状态发生变化时的回调
    handleCheckChange(data, checked, indeterminate) {
      this.checked = checked;
    },
    // async loadNode(node, resolve) {
    //   console.log(node, resolve, "---")
    //   var param = {
    //     parentId: node.data ? node.data.id : '',
    //   };
    //   console.log(param, "--param");
    //   const res = await this.$axios.post(
    //     "/httpServe/tree/selectNode",
    //     param,
    //     true
    //   );
    //   console.log(res.data.content,'---')
    //   res.data.content.forEach((item,index)=>{
    //     if(item.isParent == "true"){
    //       item.isParent = false
    //     }else {
    //       item.isParent = true
    //     }
    //   })
    //   resolve(res.data.content);
    // },

    // 转换树行数据的方法
    // transTree(list) {
    //   // 最终生成的树行结构
    //   const treeData = [];
    //   console.log(list,"list");
    //   list.forEach((item) => {
    //     // 给每一项添加子节点
    //     // item.children = []
    //     // 如果 pid 为空，说明是最顶级，直接放到 treeData 中即可
    //     // if (item.pId == "G_0") {
    //     if (item.pid == "G_0") {
    //       treeData.push(item);
    //     }

    //     // 根据前面的分析，pid 代表的是父级的 id，从而可以进行筛选子级
    //     const children = list.filter((data) => data.pid === item.id);
    //     if (!children.length) return;
    //     // 将返回的子级进行赋值给父级的 children
    //     item.children = children;
    //   });
    //   return treeData;
    // },
    //tree节点点击回调
    // handleNodeClick(data) {
    //   // .split('_')[1]
    //   //id传给子组件中获取表格数据的方法
    //   this.$refs.left_table.getList(data.id);
    //   // console.log(data.id.split('_')[1],"节点点击---")
    //   // console.log(this.$refs.left_table.getList(data.id.split('_')[1]),"ref")
    // },
    trees(val) {
      console.log(val, 'trees')
      this.defaultExpandedKeys = []
      this.defaultExpandedKeys.push(val)
      this.$refs.left_table.getList(this.defaultExpandedKeys)
    },
    sing() {
      // alert("")
      this.$refs.left_table.sing();
    },
  },
};
</script>

<style lang="scss" scoped>
.leftBox,
.rightBox {
  height: 463px;
  max-height: 650px;
  //   border: 2px solid #1d89e3;
  padding: 3px 10px 0;
  box-sizing: border-box;
}
//左侧
.leftBox {
  //第一个div内左侧树状图部分
  float: left;
  width: 580px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  margin-right: 10px;
  .leftTree {
    float: left;
    height: 440px;
    max-height: 448px;
    border-radius: 3px;
    width: 230px;
    position: relative;
    border: 1px solid #cdd1dd;
    padding: 10px 10px 0px 15px;
    display: inline-block;
    // overflow: auto;
    //鼠标拖拽图标
    .dragPoint {
      width: 21px;
      height: 21px;
      position: absolute;
      z-index: 2;
      background: #fff;
      top: 47%;
      margin-top: -15px;
      cursor: col-resize;
      display: -ms-flexbox;
      display: flex;
      justify-content: center;
    }
    //树状图
    .treeBox {
      margin-top: 10px;
      height: 379px;
      overflow-y: auto;
    }
  }
  //第一个div内右侧表格部分
  .leftTable {
    display: inline-block;
    // width: 456px;
    right: -20px;
    height: 440px;
    position: relative;
    margin-left: -2px;
    overflow-x: auto;
    width: 57% !important;
  }
}
//右侧
.rightBox {
  .el-tree-node__content {
    padding-left: 0px !important;
  }
}
</style>
<style lang="scss" scoped>
//树状图
.treeBox {
  margin-top: 10px;
  .el-tree-node:focus > .el-tree-node__content {
    background-color: #ffe6b0;
    color: black;
    border: 1px #ffb951 solid;
    // height: 18px;
    opacity: 0.8;
  }
  .el-tree-node__content {
    &:hover {
      background-color: #ffe6b0;
    }
  }
  .el-tree-node__content .el-tree-node__label {
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
  }

  //   .el-checkbox {
  //     width: 14px !important;
  //     padding: 2%;
  // }
}
::v-deep .el-tree-node__content > label.el-checkbox {
  margin-right: 8px;
  display: none;
}
.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background-color: #ffe6b0;
}
</style>
<style>
.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background-color: #ffe6b0;
}
</style>
