<template>
  <div style="height: 600px">
    <div class="lll" style="margin-left: 20px">
      <div
        id="container"
        style="width: 1000px; height: 500px; margin: 20px; position: absolute"
      ></div>
      <div id="panel"></div>
      <el-form
        ref="ruleForm"
        label-width="800px"
        class="demo-ruleForm"
        style="margin-top: 10px; margin-left: 320px"
      >
        <el-form-item :label="$t('public.StartTime') + '：'" prop="name">
          <!-- <el-input size="small" style="width: 240px"></el-input> -->
          <el-date-picker
            :clearable="false"
            v-model="value1"
            type="date"
            :placeholder="$t('DeviceManage.Device.Riskinforma.SelectDate')"
            size="small"
            style="width: 240px"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('public.EndTime') + '：'" prop="name">
          <!-- <el-input size="small" style="width: 240px"></el-input> -->
          <el-date-picker
            :clearable="false"
            v-model="value2"
            type="date"
            :placeholder="$t('DeviceManage.Device.Riskinforma.SelectDate')"
            size="small"
            style="width: 240px"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" @click="submitForm">{{
            $t("public.Inquire")
          }}</el-button>
        </el-form-item>
        <el-form-item label="">
          <el-button
            type="primary"
            :loading="loading.listloading"
            size="mini"
            @click="Refresh()"
            >{{ $t("DeviceManage.Device.Trajectory.GetLocation") }}</el-button
          >
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" size="mini" @click="ExportTrajectory()"
            >导出轨迹</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import MapLoader from "@/assets/Amap/Amap.js";
export default {
  data() {
    return {
      times: "",
      flag: 1,
      value1: new Date(),
      value2: new Date(),
      user: {},
      loading: {
        listloading: false,
      },
      timer: null,
      motime: "",
      // inRefresh: [116.4,39.92],
      inRefresh: [116.397428, 39.90923],
      numArray: 0,
    };
  },
  watch: {
    value1(val, oldVal) {
      if (!val) {
        this.value1 = "";
      }
    },
    value2(val, oldVal) {
      if (!val) {
        this.value2 = "";
      }
    },
  },
  watch: {
    times: {
      handler: function (newVal, oldVal) {
        if (newVal) {
          this.times = newVal;
          this.time = this.times;
          // this.allMethods()
        }
        deep: true;
        // immediate: true,
      },
    },
  },
  created() {
    MapLoader();
  },
  mounted: function () {
    let user = JSON.parse(window.sessionStorage.getItem("user"));
    let time = window.localStorage.getItem("time");
    this.user = user;
    this.time = time;
    setTimeout(() => {
      this.mapLits(user);
    }, 1000);
  },

  methods: {
    init: function (res) {
      console.log(res);
      var that = this;
      //基本地图加载
      var map = new AMap.Map("container", {
        resizeEnable: true,
        viewMode: "3D",
        // center: [116.397428, 39.90923], //地图中心点
        center: that.inRefresh, //地图中心点
        zoom: 17, //地图显示的缩放级别3-18
      });
      window.map = map;
      map.plugin(["AMap.ControlBar"], function () {
        // 添加 3D 罗盘控制
        map.addControl(
          new AMap.ControlBar({
            position: {
              right: "20px",
              top: "20px",
            },
            showControlButton: true, // 是否显示倾斜、旋转按钮。默认为 true
          })
        );
      });
      //构造路线导航类
      var driving = new AMap.Driving({
        map: map,
        panel: "panel",
        ferry: 1, // 是否可以使用轮渡,
        policy: AMap.DrivingPolicy.LEAST_TIME,
      });
      var arr = [];
      // var lat1 = res.data[0].latitude;
      // var log1 = res.data[0].longitude;
      // var lat3 = res.data[res.data.length - 1].latitude;
      // var log3 = res.data[res.data.length - 1].longitude;
      res.data.location.forEach((i, k) => {
        arr.push(new AMap.LngLat(i.longitude, i.latitude));
      });
      var polyline = new AMap.Polyline({
        path: arr, // 设置线覆盖物路径
        strokeColor: "#1bac2e", // 线颜色
        //   strokeOpacity: 1, // 线透明度
        strokeWeight: 6, // 线宽
        // strokeStyle: "solid", // 线样式
        //   strokeDasharray: [10, 5], // 补充线样式
        //   geodesic: true, // 绘制大地线
        showDir: true, //是否显示箭头
      });
      polyline.setMap(map);

      // 创建一个 icon 途经点
      var endIcon = new AMap.Icon({
        size: new AMap.Size(100, 34),
        image: require("../../../../images/map-marker-j.png"),
        imageSize: new AMap.Size(30, 35),
        imageOffset: new AMap.Pixel(0, 0),
      });
      // 创建一个 icon 起点
      var kaiIcon = new AMap.Icon({
        size: new AMap.Size(100, 34),
        image: require("../../../../images/map-marker-q.png"),
        imageSize: new AMap.Size(30, 35),
        imageOffset: new AMap.Pixel(0, 0),
      });
      // 创建一个 icon 终点
      var weiIcon = new AMap.Icon({
        size: new AMap.Size(100, 34),
        image: require("../../../../images/map-marker-z.png"),
        imageSize: new AMap.Size(30, 35),
        imageOffset: new AMap.Pixel(0, 0),
      });
      // 创建一个 icon   停留时间
      var stopIcon = new AMap.Icon({
        size: new AMap.Size(100, 34),
        image: require("../../../../images/map-marker-stop.png"),
        imageSize: new AMap.Size(45, 40),
        imageOffset: new AMap.Pixel(0, 0),
      });
      // 创建一个 icon   最近定位
      var latelyIcon = new AMap.Icon({
        size: new AMap.Size(100, 34),
        image: require("../../../../images/ico_zjdw.png"),
        imageSize: new AMap.Size(40, 35),
        imageOffset: new AMap.Pixel(0, 0),
      });
      var infoWindow = new AMap.InfoWindow({ offset: new AMap.Pixel(0, -30) });

      res.data.location.forEach((i, k) => {
        if (k == 0) {
          //起点
          var ar = [i.longitude, i.latitude];
          this.inRefresh = ar;
          var marker = new AMap.Marker({
            position: ar,
            icon: kaiIcon,
            map: map,
          });
          marker.content = `时&nbsp;&nbsp;&nbsp;间：${i.uploadTime}</br>经纬度：${i.longitude} ${i.latitude}`;
          marker.on("mouseover", markerClick);
          marker.emit("click", { target: marker });
        } else if (k == res.data.location.length - 1) {
          var ar = [
            res.data.location[res.data.location.length - 1].longitude,
            res.data.location[res.data.location.length - 1].latitude,
          ];
          // this.inRefresh = ar;
          var marker = new AMap.Marker({
            position: ar,
            icon: weiIcon,
            map: map,
          });
          marker.content = `时&nbsp;&nbsp;&nbsp;间：${i.uploadTime}</br>经纬度：${
            res.data.location[res.data.location.length - 1].longitude
          } ${res.data.location[res.data.location.length - 1].latitude}`;
          marker.on("mouseover", markerClick);
          marker.emit("click", { target: marker });
        } else if (i.stayTime >= 1800) {
          var ar = [i.longitude, i.latitude];
          this.inRefresh = ar;
          var marker = new AMap.Marker({
            position: ar,
            icon: stopIcon,
            map: map,
          });
          var time = that.convertSeconds(i.stayTime);
          marker.content = `时&nbsp;&nbsp;&nbsp;间：${i.uploadTime}</br>经纬度：${i.longitude} ${i.latitude}
          </br>经&nbsp;&nbsp;&nbsp;停：${time}`;
          marker.on("mouseover", markerClick);
          marker.emit("click", { target: marker });
        } else {
          var ar = [i.longitude, i.latitude];
          this.inRefresh = ar;
          var marker = new AMap.Marker({
            position: ar,
            icon: endIcon,
            map: map,
          });
          marker.content = `时&nbsp;&nbsp;&nbsp;间：${i.uploadTime}</br>经纬度：${i.longitude} ${i.latitude}`;
          marker.on("mouseover", markerClick);
          marker.emit("click", { target: marker });
        }
        // // 最近定位
        // var ars = [i.longitude, i.latitude];
        //   this.inRefresh = ars;
        //   var marker = new AMap.Marker({
        //     position: ars,
        //     icon: latelyIcon,
        //     map: map,
        //   });
        // marker.content = i.uploadTime;
        // marker.on("mouseover", markerClick);
        // marker.emit("click", { target: marker });
      });
      // 最近定位
      if (res.data.position) {
        console.log(res.data.position, "res.data.positionres.data.position=====");
        var ars = [res.data.position.longitude, res.data.position.latitude];
        this.inRefresh = ars;
        var marker = new AMap.Marker({
          position: ars,
          icon: latelyIcon,
          map: map,
        });
        marker.content = `时&nbsp;&nbsp;&nbsp;间：${res.data.position.uploadTime}</br>经纬度：${res.data.position.longitude} ${res.data.position.latitude}`;
        marker.on("mouseover", markerClick);
        marker.emit("click", { target: marker });
      }

      //   提示时间框
      var arrs = [];
      res.data.location.forEach((i, k) => {
        if (k == 0 || k == res.data.location.length - 1) {
          // alert(1);
        } else {
          arrs.push(new AMap.LngLat(i.longitude, i.latitude));
        }
      });
      function markerClick(e) {
        infoWindow.setContent(e.target.content);
        infoWindow.open(map, e.target.getPosition());
      }
      map.setFitView();
    },
    convertSeconds(seconds) {
      var days = Math.floor(seconds / (24 * 60 * 60));
      var hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
      var minutes = Math.floor((seconds % (60 * 60)) / 60);
      var remainingSeconds = seconds % 60;
      if (days > 0) {
        return `${days}天${hours}小时${minutes}分钟`;
      } else if (hours > 0) {
        return `${hours}小时${minutes}分钟`;
      } else {
        return `${minutes}分钟`;
      }
    },
    // 查询
    async submitForm() {
      let time = window.localStorage.getItem("time");
      this.time = time;
      var val1 = this.$moment(this.value1).unix();
      var val2 = this.$moment(this.value2).unix();
      if (val1 > val2) {
        this.$message({
          message: this.$t("DeviceManage.Device.Trajectory.Starttimenoendtime"),
          type: "error",
          offset: 100,
        });
        return false;
      }
      var params = {
        deviceId: this.user.id, //387
        startTime: this.$moment(this.value1).format("YYYY-MM-DD"), //"2023-10-01",//
        endTime: this.$moment(this.value2).format("YYYY-MM-DD"),
      };
      const res = await this.$axios.post("/httpServe/location/selectList", params, true);
      console.log(res.data, "res.datares.data");
      // this.refre(time);
      let result = res.data.location;
      let arr = [];
      result.forEach((ele) => {
        arr.push({
          x: ele.latitude,
          y: ele.longitude,
        });
      });
      this.init(res);
    },
    // 请求接口
    async mapLits() {
      let time = window.localStorage.getItem("time");
      this.time = time;
      var val1 = this.$moment(this.value1).unix();
      var val2 = this.$moment(this.value2).unix();
      if (val1 > val2) {
        this.$message({
          message: this.$t("DeviceManage.Device.Trajectory.Starttimenoendtime"),
          type: "error",
          offset: 100,
        });
        return false;
      }
      var params = {
        deviceId: this.user.id, //387
        startTime: this.$moment(this.value1).format("YYYY-MM-DD"), //"2023-10-01",//
        endTime: this.$moment(this.value2).format("YYYY-MM-DD"),
      };
      const res = await this.$axios.post("/httpServe/location/selectList", params, true);
      console.log(res.data, "-----");
      let result = res.data.location;
      let arr = [];
      result.forEach((ele) => {
        arr.push({
          x: ele.latitude,
          y: ele.longitude,
        });
      });
      this.init(res);
      // if(res.code == 200) {
      //   this.refre(time);
      // }
      // this.viaMarker.forEach()
    },
    // 获取定位 Refresh
    Refresh() {
      this.numArray = 0;
      // this.$moment().format('YYYY-MM-DD HH:mm:ss')
      var time = this.$moment().format("YYYY-MM-DD HH:mm:ss");
      this.$store.commit("Historicaltime", time);
      var param = {
        type: "uploadlocation", //标记状态
        deviceUDID: this.user.deviceUDID,
      };
      this.$confirm(
        this.$t("DeviceManage.Device.Trajectory.Obtainconfirmation"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          customClass: "persdsd",
          type: "warning",
        }
      ).then(async () => {
        const res = await this.$axios.post("/httpServe/device/command/exec", param, true);
        this.loading.listloading = true;
        this.timer = setInterval(() => {
          this.numArray++;
          // 需要执行的方法
          this.refre(time);
          if (this.numArray >= 12) {
            clearInterval(this.timer);
            this.loading.listloading = false;
          }
        }, 10000);
        return;
      });
    },
    // 定位接口
    async refre(time) {
      window.localStorage.setItem("time", time);
      var param = {
        deviceId: String(this.user.id),
        // updateTime: "2022-06-30 11:04:25",
        updateTime: time ? "" : "",
      };
      const res = await this.$axios.post(
        "/httpServe/devicePosition/selectDevicePosition",
        param,
        true
      );
      if (res.data != null) {
        clearInterval(this.timer);
        this.loading.listloading = false;
      }
      // 创建一个 icon   最近定位
      var latelyIcon = new AMap.Icon({
        size: new AMap.Size(100, 34),
        image: require("../../../../images/ico_zjdw.png"),
        imageSize: new AMap.Size(40, 35),
        imageOffset: new AMap.Pixel(0, 0),
      });
      var infoWindow = new AMap.InfoWindow({ offset: new AMap.Pixel(0, -30) });
      var arr = [];
      arr.push(res.data);
      arr.forEach((i, k) => {
        // if (k == 0) {
        var ars = [i.longitude, i.latitude];
        this.inRefresh = ars;
        // this.map();
        var marker = new AMap.Marker({
          position: ars,
          icon: latelyIcon,
          map: map,
        });
        // }
        marker.content = i.uploadTime;
        marker.on("mouseover", markerClick);
        marker.emit("click", { target: marker });
        window.map.clearMap(marker);
        window.map.add(marker);
      });
      //   提示时间框
      var arrs = [];
      arrs.push(res.data);
      arrs.forEach((i, k) => {
        if (k == 0) {
          // alert(1);
        } else {
          arrs.push(new AMap.LngLat(i.longitude, i.latitude));
        }
      });

      function markerClick(e) {
        infoWindow.setContent(e.target.content);
        infoWindow.open(map, e.target.getPosition());
      }
      map.setFitView();
      return;
    },
    async ExportTrajectory() {
      let time = window.localStorage.getItem("time");
      this.time = time;
      var val1 = this.$moment(this.value1).unix();
      var val2 = this.$moment(this.value2).unix();
      if (val1 > val2) {
        this.$message({
          message: this.$t("DeviceManage.Device.Trajectory.Starttimenoendtime"),
          type: "error",
          offset: 100,
        });
        return false;
      }
      let token2 = sessionStorage.getItem("Authorization")
        ? sessionStorage.getItem("Authorization")
        : "eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFM1MTIifQ.eyJleHAiOjE2MzMwMjMzNzUsInVzZXJJZCI6MSwiaWF0IjoxNjMzMDAxNzc1fQ.hSF4bNDHVFZR2h4R0osNBF56ChR_l03mwx9QYaS1E0JDtIFlme3dP8oktCjEIaGi4QzXutLRMmjFWxuDNtaMMA";
      let userString = decodeURIComponent(escape(window.atob(token2.split(".")[1])));
      // let userString = decodeURIComponent(
      //   escape(window.atob(this.token.split(".")[1]))
      // );
      this.userId = JSON.parse(userString).userId;
      let param = {
        deviceId: this.user.id,
        startTime: this.$moment(this.value1).format("YYYY-MM-DD"), //"2023-10-01",//
        endTime: this.$moment(this.value2).format("YYYY-MM-DD"),
      };
      const res = await this.$axios.postExport(
        "/httpExport/location/exportExcel",
        param,
        false,
        this.userId
      );
      const link = document.createElement("a");
      var fileName = "Location" + Date.parse(new Date()) + ".xls";
      let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
      link.style.display = "none";
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", decodeURI(fileName));
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .tear {
  width: 35px;
  height: 35px;
  border-radius: 0 50% 50% 50%;
  /* border: 1px solid #56bd77; */
  transform: rotate(-135deg);
  margin-top: 20px;
  background: #56bd77;
}

::v-deep .tears {
  color: white;
  font-size: 12px;
  transform: rotate(-135deg);
  transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}

.amap-icon img {
  width: 25px;
  height: 34px;
}

::v-deep .amap-lib-marker-from {
  display: none;
}

::v-deep .amap-lib-marker-from {
  background: url(https://webapi.amap.com/theme/v1.3/markers/n/start.png) no-repeat;
  cursor: pointer;
  display: none;
}

::v-deep .amap-lib-marker-to {
  background: url(https://webapi.amap.com/theme/v1.3/markers/n/end.png) no-repeat;
  cursor: pointer;
  display: none;
}
</style>
<style scoped>
::v-deep .amap-icon img {
  width: 25px;
  height: 34px;
}

#panel {
  display: none;
}

::v-deep .info-title {
  font-weight: bolder;
  color: #000;
  font-size: 14px;
  width: 250px;
  line-height: 26px;
  padding: 0 0 0 6px;
}

::v-deep .info-content {
  width: 250px;
  padding: 4px;
  color: #666666;
  line-height: 23px;
  font: 12px Helvetica, "Hiragino Sans GB", "Microsoft Yahei", "微软雅黑", Arial;
}

::v-deep .info-content img {
  float: left;
  margin: 3px;
}

::v-deep .amap-info-combo .keyword-input {
  height: auto;
}

::v-deep .amap-lib-marker-from {
  display: none;
}

::v-deep .amap-lib-marker-from {
  background: url(https://webapi.amap.com/theme/v1.3/markers/n/start.png) no-repeat;
  cursor: pointer;
  display: none;
}

::v-deep .amap-lib-marker-to {
  background: url(https://webapi.amap.com/theme/v1.3/markers/n/end.png) no-repeat;
  cursor: pointer;
  display: none;
}
</style>
